<template>
  <div class="shadow sm:hidden">

    <ul v-if="paginator.meta.total === 0" role="list" class="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
      <li>
        <p class="block px-4 py-4 bg-white">
          No records found
        </p>
      </li>
    </ul>

    <ul v-else role="list" class="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
      <li v-for="activity in paginator.data" :key="activity.id">
        <a href="#" class="block px-4 py-4 bg-white hover:bg-gray-50">
          <span class="flex items-center space-x-4">
            <span class="flex-1 flex space-x-2 truncate">
              <BanknotesIcon v-if="activity.type === 'Faucet'" class="flex-shrink-0 h-5 w-5 text-gray-500 group-hover:text-gray-900" aria-hidden="true" />
              <Square2StackIcon v-if="activity.type === 'Dice'" class="flex-shrink-0 h-5 w-5 text-gray-500 group-hover:text-gray-900" aria-hidden="true" />
              <ArrowRightOnRectangleIcon v-if="activity.type === 'Withdraw'" class="flex-shrink-0 h-5 w-5 text-gray-500 group-hover:text-gray-900" aria-hidden="true" />
              <ArrowLeftOnRectangleIcon v-if="activity.type === 'Deposit'" class="flex-shrink-0 h-5 w-5 text-gray-500 group-hover:text-gray-900" aria-hidden="true" />
              <span class="flex flex-col text-gray-500 text-sm truncate">
                <span>
                  <span class="text-gray-900 font-medium">{{ formatCrypto(activity.amount) }}</span> XCH
                </span>
                <span>
                  <time :datetime="activity.created_at">{{ formatDt(activity.created_at) }}</time>
                  <span
                    :class="[statusStyles[activity.status], 'ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize']">
                    {{ activity.status.toLowerCase() }}
                  </span>
                </span>
              </span>
            </span>
            <ChevronRightIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true"/>
          </span>
        </a>
      </li>
    </ul>

    <ResponsivePagination :paginator="paginator" @paginate-to="$emit('paginate-to', $event)"></ResponsivePagination>

  </div>
</template>

<script>
  import {
    ChevronRightIcon,
  } from '@heroicons/vue/20/solid';
  import {
    BanknotesIcon,
    Square2StackIcon,
    ArrowRightOnRectangleIcon,
    ArrowLeftOnRectangleIcon,
  } from '@heroicons/vue/24/outline';
  import FormatsDt from '@/mixins/FormatsDt';
  import ResponsivePagination from '@/components/pagination/ResponsivePagination';
  import FormatsCrypto from '@/mixins/FormatsCrypto';
  import HasStatusStyles from '@/mixins/HasStatusStyles';

  export default {
    components: {
      BanknotesIcon,
      Square2StackIcon,
      ChevronRightIcon,
      ResponsivePagination,
      ArrowRightOnRectangleIcon,
      ArrowLeftOnRectangleIcon,
    },

    mixins: [
      HasStatusStyles,
      FormatsDt,
      FormatsCrypto,
    ],

    props: {
      paginator: Object,
    },
  }
</script>

<style scoped>

</style>
