<template>
  <AuthLayout>
    <VWait for="data">

      <template v-slot:waiting>
        <RadarSpinner/>
      </template>

      <main class="flex-1 relative pb-8 z-0 overflow-y-auto">

      <PageHeader>
        <template #name>Withdraw</template>
        <template #control>
          <RouterLink
            :to="{ name: 'accounts.index' }"
            class="btn-default"
          >
            Wallet
          </RouterLink>
        </template>
      </PageHeader>

      <div class="mt-8">

        <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <form @submit.prevent="send">
            <div class="shadow sm:rounded-md sm:overflow-hidden">
              <div class="bg-white py-6 px-4 space-y-6 sm:p-6">

                <div>
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Available balance: <span class="whitespace-nowrap">{{ availableBalance }}</span>
                  </h3>
                  <p class="mt-1 text-sm text-gray-500">
                    Current network fee: {{ formatCrypto(this.activeCurrency.fee) }}
                  </p>
                </div>

                <div class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                  <div class="space-y-6 sm:space-y-5">

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                      <label for="account" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Currency <BadgeRequired/>
                      </label>
                      <div class="mt-1 sm:mt-0 sm:col-span-2">
                        <select :value="activeCurrency.currency_id" @input="handleSelect" id="account" autocomplete="account" class="input-text">
                          <option v-for="currency in currencies" :key="currency.currency_id" :value="currency.currency_id">
                            {{ currency.currency_name }}
                          </option>
                        </select>
                      </div>
                      <HasError class="sm:col-start-2" :form="form" field="currency_id"></HasError>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                      <label for="address" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Address <BadgeRequired/>
                      </label>
                      <div class="mt-1 sm:mt-0 sm:col-span-2">
                        <input type="text" v-model="form.address" id="address" autocomplete="address" class="input-text" />
                      </div>
                      <HasError class="sm:col-start-2" :form="form" field="address"></HasError>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                      <label for="amount" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Amount <BadgeRequired/>
                      </label>
                      <div class="mt-1 sm:mt-0 sm:col-span-2">
                        <input type="text" v-model="form.amount" id="amount" autocomplete="amount" class="input-text" />
                      </div>
                      <HasError class="sm:col-start-2" :form="form" field="amount"></HasError>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                      <label for="total_amount" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Total Amount (incl. fee)
                      </label>
                      <div class="mt-1 sm:mt-0 sm:col-span-2">
                        <input type="text" v-model="totalAmount" id="total_amount" autocomplete="total_amount" disabled="disabled" class="input-text-disabled" />
                      </div>
                    </div>

                  </div>
                </div>

              </div>
              <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button
                  type="submit"
                  class="btn-filled"
                  :class="{ 'opacity-25': form.busy }"
                  :disabled="form.busy"
                >
                  Withdraw
                </button>
              </div>
            </div>
          </form>
        </div>

      </div>
    </main>
    </VWait>
  </AuthLayout>
</template>

<script>
import AuthLayout from '@/components/layouts/AuthLayout';
import PageHeader from '@/components/PageHeader';
import DesktopTable from '@/views/Address/DesktopTable';
import ResponsiveTable from '@/views/Address/ResponsiveTable';
import BadgeRequired from '@/components/BadgeRequired';
import { Form } from 'vform';
import { HasError } from 'vform/src/components/tailwind';
import WithdrawApi from '@/api/WithdrawApi';
import FormatsCrypto from '@/mixins/FormatsCrypto';
import RadarSpinner from '@/components/RadarSpinner';
import Account from '@/resources/Account';
import Big from 'big.js';
import { mapGetters, mapState } from 'vuex';

export default {
  components: {
    RadarSpinner,
    BadgeRequired,
    ResponsiveTable,
    DesktopTable,
    PageHeader,
    AuthLayout,
    HasError,
  },

  mixins: [FormatsCrypto],

  data() {
    return {
      form: new Form({
        currency_id: '',
        address: '',
        amount: '',
      }),
    }
  },

  computed: {
    ...mapState({
      currencies: state => state.wallet.currencies,
      activeCurrencyId: state => state.wallet.activeCurrencyId,
    }),

    ...mapGetters('wallet', [
      'activeCurrency',
    ]),

    totalAmount() {
      let amount = parseFloat(this.form.amount);
      amount = isNaN(amount) ? 0 : amount;

      let totalAmount = new Big(amount).minus(this.activeCurrency.fee);
      return totalAmount.lt(0) ? '' : totalAmount;
    },

    availableBalance() {
      return `${ this.formatCrypto(this.activeCurrency.balance) } ${ this.activeCurrency.currency_symbol  }`;
    },
  },

  async created() {
    this.$wait.start('data');

    if (this.$route.query.currencyId) {
      await this.$store.dispatch('wallet/setActiveCurrencyId', this.$route.query.currencyId);
    }

    this.form.currency_id = this.activeCurrencyId;

    this.$wait.end('data');
  },

  methods: {
    async handleSelect(e) {
      await this.$store.dispatch('wallet/setActiveCurrencyId', e.target.value);
    },

    async send() {
      try {
        await WithdrawApi.store(this.form);
        this.$notify({ title: 'Request accepted!' });
        this.reset();
      } catch (e) {
        console.log(e);
      }
    },

    reset() {
      this.form.address = '';
      this.form.amount = '';
    }
  },
}
</script>
