import { Form } from 'vform';
import AccountApi from '@/api/AccountApi';
import AddressApi from '@/api/AddressApi';

const AccountService = {
  async createAccount(currencyId) {
    try {
      const form = new Form({
        currency_id: currencyId,
      })
      const response = await AccountApi.store(form);
      return response.data;
    } catch (e) {
      console.log(e);
    }
  },

  async createAddress(account) {
    try {
      const form = new Form({
        account_id: account.id,
      });
      const response = await AddressApi.store(form);
      return response.data;
    } catch (e) {
      console.log(e);
    }
  },
}

export default AccountService;
