<template>
  <AuthLayout>
    <VWait for="data">

      <template v-slot:waiting>
        <RadarSpinner/>
      </template>

      <main class="flex-1 relative pb-8 z-0 overflow-y-auto">

        <PageHeader>
          <template #name>
            <div class="inline-flex space-x-2">
              <img class="flex-shrink-0 h-5 w-5 self-center"
                   :src="require(`@/assets/icons/${ account.currency.symbol }.png`)">
              <p class="">
                {{ account.currency.name }}
              </p>
            </div>
          </template>
          <template #control>
            <RouterLink
                :to="{ name: 'deposit' }"
                class="btn-filled"
            >
              Deposit
            </RouterLink>
            <RouterLink
                :to="{ name: 'withdraw' }"
                class="btn-filled"
            >
              Withdraw
            </RouterLink>
            <RouterLink
              :to="{ name: 'accounts.index' }"
              class="btn-default"
            >
              Wallet
            </RouterLink>
          </template>
        </PageHeader>

        <div class="mt-8">

          <h2 class="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
            Transactions
          </h2>

          <ResponsiveTable
            @paginate-to="handlePagination"
            :transaction-paginator="transactionPaginator"
          ></ResponsiveTable>

          <DesktopTable
            @paginate-to="handlePagination"
            :transaction-paginator="transactionPaginator"
          ></DesktopTable>

        </div>
      </main>
    </VWait>
  </AuthLayout>
</template>

<script>
import AuthLayout from '@/components/layouts/AuthLayout';
import PageHeader from '@/components/PageHeader';
import RadarSpinner from '@/components/RadarSpinner';
import ResponsiveTable from '@/views/Transaction/ResponsiveTable';
import DesktopTable from '@/views/Transaction/DesktopTable';
import Transaction from '@/resources/Transaction';
import Account from '@/resources/Account';

export default {
    components: {
      ResponsiveTable,
      DesktopTable,
      PageHeader,
      AuthLayout,
      RadarSpinner,
    },

    data() {
      return {
        account: null,
        transactionPaginator: null,
      }
    },

    async created() {
      this.$wait.start('data');

      this.account = await Account.include(['currency']).find(this.$route.params.accountId);
      this.transactionPaginator = await Transaction.where('account_id', this.account.id).include(['account.currency']).get();

      this.$wait.end('data');
    },

    methods: {
      async handlePagination(page) {
        this.$wait.start('data');

        this.transactionPaginator = await Transaction.where('account_id', this.account.id).include(['account.currency']).page(page).get();

        this.$wait.end('data');
      },
    },
  }
</script>
