<template>
  <div class="flex w-full justify-center">
    {{ value.toFixed(this.decimals) }}
  </div>
</template>

<script>
import gsap from 'gsap'

export default {
  name: 'SlotMachine',

  props: {
    placesCount: {
      type: Number,
      default: 5,
    },
    speedSeconds: {
      type: Number,
      default: 1,
    },
    decimals: {
      type: Number,
      default: 0,
    }
  },

  data() {
    return {
      value: 0,
    };
  },

  methods: {
    turnTo(to) {
      gsap.to(this, {
        duration: this.speedSeconds,
        value: Number(to),
        onComplete: () => { this.$emit('turnCompleted') },
      });
    },

    turn(from, to) {
      this.value = from;
      const speedSeconds = Math.max(from, to);

      gsap.to(this, {
        duration: speedSeconds,
        value: Number(to),
        onComplete: () => { this.$emit('turnCompleted') },
      });
    },
  },
}
</script>

<style scoped>

</style>
