<template>
  <AuthLayout>

    <main class="flex-1 relative pb-8 z-0 overflow-y-auto">

      <PageHeader>
        <template #name>Profile</template>
        <template #control>
          <RouterLink
            :to="{ name: 'home' }"
            class="btn-default"
          >
            Home
          </RouterLink>
        </template>
      </PageHeader>

      <div class="mt-8">
        <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">

          <div class="">
            <div class="md:grid md:grid-cols-3 md:gap-6">
              <div class="md:col-span-1">
                <div class="px-4 sm:px-0">
                  <h3 class="text-lg font-medium leading-6 text-gray-900">Referrer</h3>
                  <p class="mt-1 text-sm text-gray-600">
                    Provide this link to your referrals. Earn <span class="font-medium">50%</span> of your referral faucet income forever
                  </p>
                </div>
              </div>
              <div class="mt-5 md:mt-0 md:col-span-2">
                <div class="shadow overflow-hidden sm:rounded-md">
                  <div class="px-4 py-5 bg-white sm:p-6">
                    <div class="grid grid-cols-6 gap-6">

                      <div class="col-span-6 sm:col-span-4">
                        <label for="referral-link" class="block text-sm font-medium text-gray-700">Referral Link</label>
                        <input type="text" v-model="referralLink" id="Referral-link" class="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>

                    </div>
                  </div>
                  <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button @click="copyReferralLink" type="submit" class="btn-filled">Copy</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="hidden sm:block" aria-hidden="true">
            <div class="py-5">
              <div class="border-t border-gray-200" />
            </div>
          </div>

          <div class="mt-10 sm:mt-0">
            <div class="md:grid md:grid-cols-3 md:gap-6">
              <div class="md:col-span-1">
                <div class="px-4 sm:px-0">
                  <h3 class="text-lg font-medium leading-6 text-gray-900">Update Profile</h3>
                  <p class="mt-1 text-sm text-gray-600">
                    Avatar must be JPEG, PNG or GIF and cannot exceed 10MB<br />
                    Avatar and username must not carry any abusive meaning within.
                    It includes but is not limited to violence, sexual, gender or other forms of discrimination
                  </p>
                </div>
              </div>
              <div class="mt-5 md:mt-0 md:col-span-2">
                <form @submit.prevent="updateProfile">
                  <div class="shadow overflow-hidden sm:rounded-md">
                    <div class="px-4 py-5 bg-white sm:p-6">
                      <div class="grid grid-cols-6 gap-6">

                        <div class="col-span-6 sm:col-span-4">
                          <label for="cover-photo" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Avatar <BadgeRequired/>
                          </label>
                          <div class="mt-1 flex items-center">
                            <label for="file-upload" class="cursor-pointer inline-block bg-gray-100 rounded-full overflow-hidden h-32 w-32">
                              <input id="file-upload" name="file-upload" type="file" class="sr-only" @change="uploadAvatar" />
                              <img v-if="preview" :src="preview" class="w-full h-full">
                              <svg v-else class="mx-auto my-10 h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                            </label>
                          </div>
                          <HasError class="sm:col-start-2" :form="profileForm" field="avatar"></HasError>
                        </div>

                        <div class="col-span-6 sm:col-span-4">
                          <label for="name" class="block text-sm font-medium text-gray-700">Username <BadgeRequired /></label>
                          <input type="text" v-model="profileForm.name" id="name" class="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                          <HasError :form="profileForm" field="name"></HasError>
                        </div>

                      </div>
                    </div>
                    <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                      <button
                          type="submit"
                          class="btn-filled"
                          :class="{ 'opacity-25': profileForm.busy }"
                          :disabled="profileForm.busy"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div class="hidden sm:block" aria-hidden="true">
            <div class="py-5">
              <div class="border-t border-gray-200" />
            </div>
          </div>

          <div class="mt-10 sm:mt-0">
            <div class="md:grid md:grid-cols-3 md:gap-6">
              <div class="md:col-span-1">
                <div class="px-4 sm:px-0">
                  <h3 class="text-lg font-medium leading-6 text-gray-900">Update Password</h3>
                  <p class="mt-1 text-sm text-gray-600">
                    Ensure your account is using a long, random password to stay secure.
                  </p>
                </div>
              </div>
              <div class="mt-5 md:mt-0 md:col-span-2">
                <form @submit.prevent="updatePassword">
                  <div class="shadow overflow-hidden sm:rounded-md">
                    <div class="px-4 py-5 bg-white sm:p-6">
                      <div class="grid grid-cols-6 gap-6">

                        <div class="col-span-6 sm:col-span-4">
                          <label for="current-password" class="block text-sm font-medium text-gray-700">Current Password <BadgeRequired /></label>
                          <div class="relative">
                            <input
                                :type="isVisibleCurrentPassword ? 'text' : 'password'" v-model="passwordForm.current_password" id="current-password"
                                class="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                            <div class="absolute inset-y-0 right-0 flex items-center pr-3">
                              <EyeIcon @click="isVisibleCurrentPassword = true" v-if="!isVisibleCurrentPassword" class="h-5 w-5 text-gray-500 cursor-pointer" aria-hidden="true"/>
                              <EyeSlashIcon @click="isVisibleCurrentPassword = false" v-if="isVisibleCurrentPassword" class="h-5 w-5 text-gray-500 cursor-pointer" aria-hidden="true"/>
                            </div>
                          </div>
                          <HasError :form="passwordForm" field="current_password"></HasError>
                        </div>

                        <div class="col-span-6 sm:col-span-4">
                          <label for="password" class="block text-sm font-medium text-gray-700">New Password <BadgeRequired /></label>
                          <div class="relative">
                            <input
                                :type="isVisiblePassword ? 'text' : 'password'" v-model="passwordForm.password" id="password"
                                class="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                            <div class="absolute inset-y-0 right-0 flex items-center pr-3">
                              <EyeIcon @click="isVisiblePassword = true" v-if="!isVisiblePassword" class="h-5 w-5 text-gray-500 cursor-pointer" aria-hidden="true"/>
                              <EyeSlashIcon @click="isVisiblePassword = false" v-if="isVisiblePassword" class="h-5 w-5 text-gray-500 cursor-pointer" aria-hidden="true"/>
                            </div>
                          </div>
                          <HasError :form="passwordForm" field="password"></HasError>
                        </div>

                        <div class="col-span-6 sm:col-span-4">
                          <label for="password-confirmation" class="block text-sm font-medium text-gray-700">Confirm Password <BadgeRequired /></label>
                          <div class="relative">
                            <input
                                :type="isVisiblePasswordConfirmation ? 'text' : 'password'" v-model="passwordForm.password_confirmation" id="password-confirmation"
                                class="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                            <div class="absolute inset-y-0 right-0 flex items-center pr-3">
                              <EyeIcon @click="isVisiblePasswordConfirmation = true" v-if="!isVisiblePasswordConfirmation" class="h-5 w-5 text-gray-500 cursor-pointer" aria-hidden="true"/>
                              <EyeSlashIcon @click="isVisiblePasswordConfirmation = false" v-if="isVisiblePasswordConfirmation" class="h-5 w-5 text-gray-500 cursor-pointer" aria-hidden="true"/>
                            </div>
                          </div>
                          <HasError :form="passwordForm" field="password_confirmation"></HasError>
                        </div>

                      </div>
                    </div>
                    <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                      <button
                        type="submit"
                        class="btn-filled"
                        :class="{ 'opacity-25': passwordForm.busy }"
                        :disabled="passwordForm.busy"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div class="hidden sm:block" aria-hidden="true">
            <div class="py-5">
              <div class="border-t border-gray-200" />
            </div>
          </div>

        </div>
      </div>
    </main>

  </AuthLayout>
</template>

<script>
  import AuthLayout from '@/components/layouts/AuthLayout';
  import PageHeader from '@/components/PageHeader';
  import { Form } from 'vform';
  import ProfileApi from '@/api/ProfileApi';
  import { HasError } from 'vform/src/components/tailwind';
  import {mapState} from 'vuex';
  import {copyText} from 'vue3-clipboard';
  import AuthApi from '@/api/AuthApi';
  import BadgeRequired from '@/components/BadgeRequired';
  import { EyeIcon, EyeSlashIcon } from '@heroicons/vue/20/solid'

  export default {
    components: {
      BadgeRequired,
      HasError,
      PageHeader,
      AuthLayout,
      EyeIcon,
      EyeSlashIcon,
    },

    data() {
      return {
        isVisiblePassword: false,
        isVisiblePasswordConfirmation: false,
        isVisibleCurrentPassword: false,
        preview: null,
        profileForm: new Form({
          avatar: null,
          name: '',
        }),
        passwordForm: new Form({
          current_password: '',
          password: '',
          password_confirmation: '',
        }),
      };
    },

    computed: {
      ...mapState({
        profile: state => state.auth.profile,
      }),

      referralLink() {
        const route = this.$router.resolve({ name: 'register', query: { r: this.profile.id } });
        return new URL(route.href, window.location.origin).href;
      }
    },

    watch: {
      profile(profile) {
        this.profileForm.name = profile.name;
        this.preview = profile.profile_photo_url;
      }
    },

    created() {
      this.profileForm.name = this.profile.name;
      this.preview = this.profile.profile_photo_url;
    },

    methods: {
      async copyReferralLink() {
        try {
          await copyText(this.referralLink, undefined, () => {});
          this.$notify({
            'title': 'Successfully copied!',
          })
        } catch (e) {
          this.$notify({
            'type': 'error',
            'title': 'Oops. Something went wrong. Please copy manually',
          })
        }
      },

      uploadAvatar(e) {
        const file = e.target.files[0];
        this.preview = URL.createObjectURL(file);
        this.profileForm.avatar = file;
      },

      async updateProfile() {
        try {
          await ProfileApi.update(this.profileForm);

          const response = await AuthApi.profile();
          await this.$store.dispatch('auth/setProfile', response.data);
          this.$notify({ title: 'Successfully updated!' });
        } catch (e) {
          console.log(e);
        }
      },

      async updatePassword() {
        try {
          await ProfileApi.updatePassword(this.passwordForm);
          this.$notify({ title: 'Successfully updated!' });
        } catch (e) {
          console.log(e);
        } finally {
          this.passwordForm.reset();
        }
      },
    }
  }
</script>
