<template>
  <AuthLayout>
    <VWait for="data">

      <template v-slot:waiting>
        <RadarSpinner/>
      </template>

      <main class="flex-1 relative pb-8 z-0 overflow-y-auto">

        <PageHeader>
          <template #name>Spinner</template>
          <template #control>
            <RouterLink
                :to="{ name: 'home' }"
                class="btn-default"
            >
              <HomeIcon class="h-4 w-4 text-cyan-600" aria-hidden="true"></HomeIcon>
            </RouterLink>
            <RouterLink
                :to="{ name: 'spinner.index' }"
                class="btn-filled"
            >
              History
            </RouterLink>
            <RouterLink
                :to="{ name: 'spinner.leaderboard' }"
                class="btn-filled"
            >
              Top
            </RouterLink>
          </template>
        </PageHeader>

        <div class="mt-8">

          <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-2">

            <form @submit.prevent="run">
              <div class="shadow sm:rounded-md sm:overflow-hidden">
                <div class="bg-white py-6 px-4 space-y-6 sm:p-6">

                  <div class="space-y-6 sm:space-y-5">
                    <div class="space-y-6 sm:space-y-5">

                      <Disclosure as="div" v-slot="{ open }">
                        <dt class="text-lg">
                          <DisclosureButton class="text-left w-full flex justify-between items-start text-gray-400">
                            <span class="font-medium text-gray-900">
                              Provably fair
                            </span>
                            <span class="ml-6 h-7 flex items-center">
                              <ChevronDownIcon :class="[open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform']" aria-hidden="true" />
                            </span>
                          </DisclosureButton>
                        </dt>
                        <DisclosurePanel as="dd" class="mt-2 space-y-6 sm:space-y-5">
                          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label for="server_seed_hash" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Server seed hash (sha256)
                            </label>
                            <div class="mt-1 sm:mt-0 sm:col-span-2">
                              <input type="text" v-model="form.server_seed_hash" disabled="disabled" id="server_seed_hash" class="input-text-disabled" />
                            </div>
                          </div>

                          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label for="client_seed" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Client seed
                            </label>
                            <div class="mt-1 sm:mt-0 sm:col-span-2">
                              <input type="text" @keydown.enter.prevent v-model="form.client_seed" id="client_seed" class="input-text" />
                            </div>
                            <HasError class="sm:col-start-2" :form="form" field="client_seed"></HasError>
                          </div>

                          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label for="client_seed" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Nonce
                            </label>
                            <div class="mt-1 sm:mt-0 sm:col-span-2">
                              <input type="number" min="1" v-model="form.nonce" id="nonce" disabled="disabled" class="input-text-disabled" />
                            </div>
                          </div>
                        </DisclosurePanel>
                      </Disclosure>

                      <HorizontalSpinner
                          ref="roulette"
                          @spinCompleted="notifyReward"
                      />

                      <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="bet" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          Bet (<span class="font-bold">{{ activeCurrency.currency_symbol }}</span>)
                        </label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                          <CurrencyInput
                              @keydown.enter.prevent v-model.lazy="form.bet"
                              id="bet"
                              :options="{
                                currency: 'USD',
                                currencyDisplay: 'hidden',
                                valueRange: {
                                  min: minBet,
                                  max: maxBet,
                                },
                                precision: {
                                  min: 0,
                                  max: 12,
                                },
                                hideCurrencySymbolOnFocus: true,
                                hideGroupingSeparatorOnFocus: true,
                                hideNegligibleDecimalDigitsOnFocus: true,
                                autoDecimalDigits: false,
                                autoSign: false,
                                useGrouping: true,
                                accountingSign: false
                              }"
                              :min-value="minBet" :max-value="maxBet"
                          ></CurrencyInput>
                        </div>
                        <HasError class="sm:col-start-2" :form="form" field="bet"></HasError>
                      </div>

                      <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <div class="mt-1 sm:mt-0 sm:col-span-2 sm:col-start-2">
                          <span class="relative z-0 inline-flex shadow-sm rounded-md w-80">
                            <button type="button" @click.prevent="betMin()" class="btn-default flex-1 justify-center">Min</button>
                            <button type="button" @click.prevent="betTwice()" class="btn-default flex-1 justify-center ml-2">X2</button>
                            <button type="button" @click.prevent="betHalf()" class="btn-default flex-1 justify-center ml-2">1/2X</button>
                            <button type="button" @click.prevent="betMax()" class="btn-default flex-1 justify-center ml-2">Max</button>
                          </span>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>
                <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <button @click="setSide('HEART')"
                          type="submit"
                          class="btn-filled"
                          :class="{ 'opacity-25': form.busy }"
                          :disabled="form.busy"
                  >
                    <HeartIcon class="h-6 w-6" aria-hidden="true"></HeartIcon>
                    X2
                  </button>
                  <button @click="setSide('PLAYCET')"
                          type="submit"
                          class="btn-filled ml-5"
                          :class="{ 'opacity-25': form.busy }"
                          :disabled="form.busy"
                  >
                    <img class="h-6 w-auto" src="/img/logo-white.svg" alt="playcet"/>
                    X14
                  </button>
                  <button @click="setSide('GIFT')"
                          type="submit"
                          class="btn-filled ml-5"
                          :class="{ 'opacity-25': form.busy }"
                          :disabled="form.busy"
                  >
                    <GiftIcon class="h-6 w-6" aria-hidden="true"></GiftIcon>
                    X2
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div class="mt-8">
            <ResponsiveTable
                @paginate-to="handlePagination"
                :paginator="spinnerPaginator"
            ></ResponsiveTable>

            <DesktopTable
                @paginate-to="handlePagination"
                :paginator="spinnerPaginator"
            ></DesktopTable>
          </div>

        </div>
      </main>
    </VWait>
  </AuthLayout>
</template>

<script>
import AuthLayout from '@/components/layouts/AuthLayout';
import PageHeader from '@/components/PageHeader';
import RadarSpinner from '@/components/RadarSpinner';
import BadgeRequired from '@/components/BadgeRequired';
import Form from 'vform';
import {HasError} from 'vform/src/components/tailwind';
import {Disclosure, DisclosureButton, DisclosurePanel} from '@headlessui/vue';
import {ChevronDownIcon, HomeIcon} from '@heroicons/vue/20/solid';
import SlotMachine from '@/components/SlotMachine.vue';
import VueCountdown from '@chenfengyuan/vue-countdown';
import FormatsCrypto from '@/mixins/FormatsCrypto';
import VueHcaptcha from '@hcaptcha/vue3-hcaptcha';
import CurrencyInput from '@/components/CurrencyInput';
import {mapGetters, mapState} from 'vuex';
import GensClientSeed from '@/mixins/GensClientSeed';
import Spinner from '@/resources/Spinner';
import Big from 'big.js';
import SpinnerApi from '@/api/SpinnerApi';
import DesktopTable from '@/views/Spinner/DesktopTable';
import ResponsiveTable from '@/views/Spinner/ResponsiveTable';
import { reactive } from 'vue'
import HasBets from '@/mixins/HasBets'
import { GiftIcon, HeartIcon } from '@heroicons/vue/24/outline';
import PlaycetIcon from '@/components/PlaycetIcon.vue';
import HorizontalSpinner from '@/components/spinner/HorizontalSpinner.vue';

export default {
  components: {
    RadarSpinner,
    PageHeader,
    AuthLayout,
    BadgeRequired,
    HasError,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronDownIcon,
    SlotMachine,
    VueCountdown,
    VueHcaptcha,
    CurrencyInput,
    DesktopTable,
    ResponsiveTable,
    HomeIcon,
    GiftIcon,
    HeartIcon,
    PlaycetIcon,
    HorizontalSpinner,
  },

  mixins: [FormatsCrypto, GensClientSeed, HasBets],

  data() {
    return {
      form: new Form({
        server_seed_hash: '',
        client_seed: '',
        nonce: '',
        bet: 0,
        side: '',
      }),
      currentSpinner: null,
      spinnerPaginator: reactive([]),
    };
  },

  async created() {
    this.$wait.start('data');

    this.currentSpinner = await Spinner.custom('spinners/current').first();
    this.resetForm();
    this.betMin();
    this.spinnerPaginator = await Spinner.get();

    this.$echo
        .channel('spinner')
        .listen('.spinner.completed', (payload) => {
          setTimeout(() => { this.addSpinner(payload.spinner) }, 500)
        });

    this.$wait.end('data');
  },

  beforeUnmount() {
    this.$echo.leave('spinner');
  },

  computed: {
    prize() {
      Big.NE = -12;
      return (new Big(this.form.bet ?? 0)).mul(2).toString();
    },

    ...mapState({
      profile: state => state.auth.profile,
    }),

    ...mapGetters('persistentConfig', [
        'minSpinnerBet',
        'maxSpinnerBet',
    ]),

    ...mapGetters('wallet', [
       'activeCurrency',
    ]),

    minBet() {
      return this.minSpinnerBet;
    },

    maxBet() {
      return new Big(this.activeCurrency.balance).lt(this.maxSpinnerBet)
          ? this.activeCurrency.balance
          : this.maxSpinnerBet;
    }
  },

  methods: {
    async handlePagination(page) {
      this.$wait.start('data');

      this.spinnerPaginator = await Spinner.page(page).get();

      this.$wait.end('data');
    },

    addSpinner(spinner) {
      if (this.spinnerPaginator.meta.current_page !== 1) {
        return;
      }

      this.spinnerPaginator.data.unshift(spinner);
      const perPage = this.spinnerPaginator.meta.per_page;

      if (this.spinnerPaginator.data.length > perPage) {
        this.spinnerPaginator.data = this.spinnerPaginator.data.slice(0, -1);
      }
    },

    resetForm() {
      this.form.server_seed_hash = this.currentSpinner.server_seed_hash;
      this.form.client_seed = this.genClientSeed();
      this.form.nonce = this.currentSpinner.nonce;
    },

    setSide(value) {
      this.form.side = value;
    },

    notifyReward() {
      if (this.lastSpinner === null) {
        return;
      }

      const type = this.lastSpinner.reward > 0 ? 'success' : 'error';
      const text = this.lastSpinner.reward > 0
          ? `Reward: ${this.formatCrypto(this.lastSpinner.reward)}`
          : `Lost: ${this.formatCrypto(this.lastSpinner.reward.substring(1))}`

      this.$notify({
        type: type,
        text: text,
        duration: 500,
      });
    },

    async run() {
      try {
        this.form.account_id = this.activeCurrency.account_id;

        const { data } = await SpinnerApi.store(this.form);
        this.currentSpinner = data.current;
        this.lastSpinner = data.last;
        this.$refs.roulette.toIndex(this.lastSpinner.value);
        this.resetForm();
      } catch (e) {
        console.log(e);
      }
    },
  },
}
</script>
