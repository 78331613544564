<template>
  <div class="bg-white overflow-hidden shadow rounded-lg">
    <div class="p-5">
      <div class="flex items-center">
        <div class="flex-shrink-0">
          <component :is="icons.get(stat.icon)" class="h-6 w-6 text-gray-400" aria-hidden="true" />
        </div>
        <div class="ml-5 w-0 flex-1">
          <dl>
            <dt class="text-sm font-medium text-gray-500 truncate">
              {{ stat.name }}
            </dt>
            <dd>
              <div class="text-lg font-medium text-gray-900">
                <span class="text-gray-500 font-medium">{{ activeCurrency.currency_symbol }}</span> {{ formatCrypto(stat.amount) }}
              </div>
            </dd>
          </dl>
        </div>
      </div>
    </div>
    <div class="bg-gray-50 px-5 py-3">
      <div class="text-sm">
        <RouterLink :to="{ name: stat.href }" class="font-medium text-cyan-700 hover:text-cyan-900">
          View
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BanknotesIcon,
  ArrowPathIcon,
  Square2StackIcon,
} from '@heroicons/vue/24/outline'
import FormatsCrypto from '@/mixins/FormatsCrypto';
import { mapGetters } from 'vuex';

export default {
  mixins: [FormatsCrypto],

  props: {
    stat: Object,
  },

  data() {
    return {
      icons: new Map([
        ['ArrowPathIcon', ArrowPathIcon],
        ['Square2StackIcon', Square2StackIcon],
        ['BanknotesIcon', BanknotesIcon],
      ]),
    };
  },

  computed: {
    ...mapGetters('wallet', [
      'activeCurrency',
    ]),
  },
};
</script>

<style scoped>

</style>
