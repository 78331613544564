<template>

  <div class="relative z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200 lg:border-none">
    <button type="button" class="px-4 border-r border-gray-200 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500 lg:hidden" @click="openSidebar">
      <span class="sr-only">Open sidebar</span>
      <Bars3CenterLeftIcon class="h-6 w-6" aria-hidden="true" />
    </button>

    <!-- Search bar -->
    <div class="flex-1 px-2 sm:ml-4 flex justify-between sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
      <div class="flex-1 flex">

      </div>
      <div class="flex items-center md:ml-6">
        <CurrencySwitcher/>
        <RouterLink :to="{ name: 'posts.index' }" class="ml-1 sm:ml-3 relative bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500">
          <span class="sr-only">View notifications</span>
          <BellIcon class="h-6 w-6" aria-hidden="true" />
          <span v-if="profile.unread_posts_count > 0" class="absolute -top-2 -right-2 h-5 w-5 rounded-full bg-green-100 text-green-800 text-xs font-medium flex justify-center items-center items">
            <span>{{ profile.unread_posts_count }}</span>
          </span>
        </RouterLink>

        <!-- Profile dropdown -->
        <Menu as="div" class="ml-1 sm:ml-3 relative">
          <div>
            <MenuButton class="max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 lg:p-2 lg:rounded-md lg:hover:bg-gray-50">
              <img class="h-8 w-8 rounded-full" :src="profile.profile_photo_url" alt="" />
              <span class="hidden ml-3 text-gray-700 text-sm font-medium lg:block"><span class="sr-only">Open user menu for </span>{{ profile.name ?? profile.email }}</span>
              <ChevronDownIcon class="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block" aria-hidden="true" />
            </MenuButton>
          </div>
          <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <MenuItems class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <MenuItem v-slot="{ active }">
                <RouterLink :to="{ name: 'profile' }" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Profile</RouterLink>
              </MenuItem>
              <MenuItem v-slot="{ active }">
                <RouterLink :to="{ name: 'referrals' }" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Referrals</RouterLink>
              </MenuItem>
              <MenuItem v-slot="{ active }">
                <a href="#" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']" @click="logout">Logout</a>
              </MenuItem>
            </MenuItems>
          </transition>
        </Menu>
      </div>
    </div>

    <button type="button" class="px-4 border-l border-gray-200 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500 lg:hidden" @click="openChat">
      <span class="sr-only">Open sidebar</span>
      <ChatBubbleOvalLeftEllipsisIcon class="h-6 w-6" aria-hidden="true" />
    </button>

  </div>
</template>

<script>
  import { Menu, MenuButton, MenuItem, MenuItems, } from '@headlessui/vue'
  import {BellIcon, ChatBubbleOvalLeftEllipsisIcon, Bars3CenterLeftIcon} from '@heroicons/vue/24/outline';
  import {ChevronDownIcon, CircleStackIcon, MagnifyingGlassIcon,} from '@heroicons/vue/20/solid'
  import AuthApi from '@/api/AuthApi';
  import { mapState } from 'vuex';
  import FormatsCrypto from "@/mixins/FormatsCrypto";
  import CurrencySwitcher from '@/components/CurrencySwitcher.vue'

  export default {
    components: {
      Menu,
      MenuButton,
      MenuItem,
      MenuItems,
      BellIcon,
      ChevronDownIcon,
      Bars3CenterLeftIcon,
      MagnifyingGlassIcon,
      ChatBubbleOvalLeftEllipsisIcon,
      CircleStackIcon,
      CurrencySwitcher,
    },

    mixins: [FormatsCrypto],

    emits: {
      sidebarToggled: Boolean,
      chatToggled: Boolean,
    },

    computed: {
      ...mapState({
        profile: state => state.auth.profile,
      }),
    },

    methods: {
      openSidebar() {
          this.$emit('sidebarToggled', true);
      },

      openChat() {
        this.$emit('chatToggled', true);
      },

      async logout() {
        try {
          await AuthApi.logOut();
          await this.$store.dispatch('auth/logOut');
          await this.$router.push({ path: '/' });
        } catch (e) {
          console.log(e);
        }
      }
    }
  }
</script>

<style scoped>

</style>
