import AuthService from '@/service/AuthService';

export default {
  namespaced: true,

  state: {
    profile: null,
    loggedIn: AuthService.isLoggedIn(),
  },

  mutations: {
    logIn(state) {
      state.loggedIn = true;
    },

    logOut(state) {
      state.loggedIn = false;
    },

    setProfile(state, profile) {
      state.profile = profile;
    },
  },

  getters: {

  },

  actions: {
    logIn(context, { token, remember }) {
      context.commit('logIn');
      AuthService.logIn(token, remember);
    },

    logOut(context) {
      context.commit('logOut');
      AuthService.logOut();
    },

    setProfile(context, profileWithWallet) {
      const { accounts, ...profile } = profileWithWallet;
      context.commit('setProfile', profile);
    }
  },
}
