<template>
  <AuthLayout>
    <main class="flex-1 relative pb-8 z-0 overflow-y-auto">

      <PageHeader>
        <template #name>Verify Email</template>
        <template #control>
          <RouterLink
            :to="{ name: 'home' }"
            class="btn-default"
          >
            Home
          </RouterLink>
        </template>
      </PageHeader>

      <div class="mt-8">

        <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <form @submit.prevent="verify">
            <div class="shadow sm:rounded-md sm:overflow-hidden">
              <div class="bg-white py-6 px-4 space-y-6 sm:p-6">

                <div>
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Please verify your email
                  </h3>
                  <p class="mt-1 text-sm text-gray-500">
                    Verified accounts are able to use our service without any limitations
                  </p>
                </div>

              </div>
              <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button
                  type="submit"
                  class="btn-filled"
                  :class="{ 'opacity-25': form.busy }"
                  :disabled="form.busy"
                >
                  Send Verification Link
                </button>
              </div>
            </div>
          </form>
        </div>

      </div>
    </main>
  </AuthLayout>
</template>

<script>
  import AuthLayout from '@/components/layouts/AuthLayout';
  import PageHeader from '@/components/PageHeader';
  import BadgeRequired from '@/components/BadgeRequired';
  import { Form } from 'vform';
  import { HasError } from 'vform/src/components/tailwind';
  import AuthApi from '@/api/AuthApi';

  export default {
    components: {
      BadgeRequired,
      PageHeader,
      AuthLayout,
      HasError,
    },

    data() {
      return {
        form: new Form(),
      };
    },

    methods: {
      async verify() {
        try {
          const response = await AuthApi.sendEmailVerificationLink(this.form);
          if (response.status === 202) {
            this.$notify({ title: 'Successfully sent!' });
          } else {
            this.$router.push({ name: 'home' });
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
  }
</script>
