<template>
  <nav v-if="isVisible" class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200" aria-label="Pagination">
    <div class="flex-1 flex justify-between">
      <button v-if="paginator.links.prev" @click="prev"
         class="btn-default">
        Previous
      </button>
      <div v-else></div>
      <button v-if="paginator.links.next" @click="next"
         class="ml-3 btn-default">
        Next
      </button>
    </div>
  </nav>
</template>

<script>
  export default {
    emits: {
      paginateTo: Number,
    },

    props: {
      paginator: Object,
    },

    computed: {
      isVisible() {
        return this.paginator.meta.total > this.paginator.meta.per_page;
      }
    },

    methods: {
      prev() {
        this.$emit('paginateTo', this.paginator.meta.current_page - 1);
      },

      next() {
        this.$emit('paginateTo', this.paginator.meta.current_page + 1);
      }
    },
  }
</script>

<style scoped>

</style>
