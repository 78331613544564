import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/app.css'
import './config/axios'
import Notifications from '@kyvg/vue3-notification'
import { Model } from 'vue-api-query'
import axios from 'axios';
import { createVueWait } from 'vue-wait';
import VueClipboard from 'vue3-clipboard';
import VueGtag from 'vue-gtag';
import VueEcho from '@/plugins/vue-echo';
import Pusher from 'pusher-js';
import vClickOutside from "click-outside-vue3";
import vueEchoConfig from "@/config/vue-echo";
import VueMobileDetection from 'vue-mobile-detection';
import gsap from 'gsap'

window.Pusher = Pusher;

require('typeface-inter');

Model.$http = axios;

gsap.ticker.fps(30);

createApp(App)
    .use(store)
    .use(router)
    .use(Notifications, ({
      componentName: 'Notifications',
    }))
    .use(createVueWait({
      componentName: 'VWait',
    }))
    .use(VueClipboard, {
      autoSetContainer: true,
      appendToBody: true,
    })
    .use(VueGtag,{
      config: { id: 'G-Y0K15BTDPR' }
    }, router)
    .use(VueEcho, vueEchoConfig)
    .use(vClickOutside)
    .use(VueMobileDetection)
    .mount('#app');
