<template>
  <span class="text-red-600">*</span>
</template>

<script>
  export default {
    name: "BadgeRequired"
  }
</script>

<style scoped>

</style>
