<template>
  <!-- Dynamic sidabar for mobile -->
  <TransitionRoot as="template" :show="chatOpen" class="md:hidden">
    <aside class="block flex-shrink-0">
      <div class="h-full relative flex flex-col w-screen bg-gray-100">
        <div class="flex-shrink-0 flex bg-white">
          <div class="h-16 bg-white px-6 flex flex-col justify-center grow">
            <div class="flex items-baseline space-x-3 justify-between">
              <h2 class="text-lg font-medium text-gray-900">Online Chat</h2>
            </div>
          </div>
          <button type="button" @click="closeChat"
                  class="px-4 border-l border-gray-200 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500 lg:hidden">
            <span class="sr-only">Open sidebar</span>
            <XMarkIcon class="h-6 w-6" aria-hidden="true"/>
          </button>
        </div>
        <nav aria-label="Message list"
             class="min-h-0 flex-1 overflow-y-auto border-t border-b border-gray-200 bg-white">
          <ul role="list">
            <li v-for="message in messages" :key="message.id" class="py-5 px-6 break-all">
              <div class="flex space-x-3">
                <div class="flex-shrink-0">
                  <img class="h-10 w-10 rounded-full" :src="message.user.profile_photo_url" alt=""/>
                </div>
                <div>
                  <div class="text-sm">
                    <a href="#" class="font-medium text-gray-900">{{ message.user.name }}</a>
                  </div>
                  <div class="mt-1 text-sm text-gray-700">
                    <p class="break-words whitespace-pre-wrap [word-break:break-word]" v-html="transformToHtml(message.body)"></p>
                  </div>
                  <div class="mt-2 text-sm space-x-2">
                    <span class="text-gray-500 font-medium">{{ formatDt(message.created_at) }}</span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </nav>

        <div class="bg-gray-50 px-4 py-6 sm:px-6">
          <div class="flex space-x-3">
            <div class="min-w-0 flex-1">
              <form @submit.prevent="send">
                <div>
                  <label for="comment" class="sr-only">About</label>
                  <ChatTextarea
                      @enter="send"
                      @update:cursorPosition="handleUpdateCursorPosition"
                      v-model="form.body"
                  ></ChatTextarea>
                </div>
                <div class="mt-3 flex items-center justify-between relative">
                  <div class="flex">
                    <p @mousedown.prevent="showMobilePicker = !showMobilePicker" class="group inline-flex items-start text-sm space-x-2 text-gray-500 hover:text-gray-900 cursor-pointer">
                      <span id="picker-trigger-mobile" class="emoji-set-apple emoji-type-image" style="background-position: 55% 46.67%; width: 24px; height: 24px;"></span>
                    </p>
                    <Picker
                        v-show="showMobilePicker"
                        v-click-outside="handleOutsideMobilePickerClick"
                        :data="emojiIndex"
                        set="apple"
                        @select="selectEmoji"
                        :show-preview="false"
                        class="absolute bottom-36 left-0"
                    />
                    <RouterLink :to="{ name: 'terms-of-service', hash: '#chat' }" class="ml-3 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize hover:underline">
                      <QuestionMarkCircleIcon class="flex-shrink-0 mr-1.5 h-5 w-5 text-cyan-700" aria-hidden="true" />
                    </RouterLink>
                  </div>

                  <button class="btn-filled"
                          :class="{ 'opacity-25': form.busy }"
                          :disabled="form.busy"
                  >
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

      </div>
    </aside>
  </TransitionRoot>

  <!-- Static sidebar for desktop -->
  <aside class="hidden xl:block xl:flex-shrink-0">
    <div class="h-full relative flex flex-col w-96 border-l border-gray-200 bg-gray-100">
      <div class="flex-shrink-0">
        <div class="h-16 bg-white px-6 flex flex-col justify-center">
          <div class="flex items-baseline space-x-3">
            <h2 class="text-lg font-medium text-gray-900">Online Chat</h2>
          </div>
        </div>
      </div>
      <nav aria-label="Message list" id="desktop-message-list"
           class="min-h-0 flex-1 overflow-y-auto border-t border-b border-gray-200 bg-white">
        <ul role="list" class="">
          <li v-for="message in messages" :key="message.id" class="py-5 px-6 break-all">
            <div class="flex space-x-3">
              <div class="flex-shrink-0">
                <img class="h-10 w-10 rounded-full" :src="message.user.profile_photo_url" alt=""/>
              </div>
              <div>
                <div class="text-sm">
                  <a href="#" class="font-medium text-gray-900">{{ message.user.name }}</a>
                </div>
                <div class="mt-1 text-sm text-gray-700">
                  <p class="break-words whitespace-pre-wrap [word-break:break-word]" v-html="transformToHtml(message.body)"></p>
                </div>
                <div class="mt-2 text-sm space-x-2">
                  <span class="text-gray-500 font-medium">{{ formatDt(message.created_at) }}</span>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </nav>

      <div class="bg-gray-50 px-4 py-6 sm:px-6">
        <div class="flex space-x-3">
          <div class="min-w-0 flex-1">
            <form @submit.prevent="send">
              <div>
                <label for="comment" class="sr-only">About</label>
                <ChatTextarea
                    @enter="send"
                    @update:cursorPosition="handleUpdateCursorPosition"
                    v-model="form.body"
                ></ChatTextarea>
              </div>
              <div class="mt-3 flex items-center justify-between relative">
                <div class="flex">
                  <p @mousedown.prevent="showDesktopPicker = !showDesktopPicker" class="group inline-flex items-start text-sm space-x-2 text-gray-500 hover:text-gray-900 cursor-pointer">
                    <span id="picker-trigger-desktop" class="emoji-set-apple emoji-type-image" style="background-position: 55% 46.67%; width: 24px; height: 24px;"></span>
                  </p>
                  <Picker
                      v-show="showDesktopPicker"
                      v-click-outside="handleOutsideDesktopPickerClick"
                      :data="emojiIndex"
                      set="apple"
                      @select="selectEmoji"
                      :show-preview="false"
                      class="absolute bottom-36 left-0"
                      style="max-width:335px !important;"
                  />
                  <RouterLink :to="{ name: 'terms-of-service', hash: '#chat' }" class="ml-3 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize hover:underline">
                    <QuestionMarkCircleIcon class="flex-shrink-0 mr-1.5 h-5 w-5 text-cyan-700" aria-hidden="true" />
                  </RouterLink>
                </div>

                <button class="btn-filled"
                        :class="{ 'opacity-25': form.busy }"
                        :disabled="form.busy"
                >
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>
  </aside>
</template>

<script>
import {Dialog, DialogOverlay, TransitionChild, TransitionRoot} from '@headlessui/vue';
import {ChatBubbleLeftEllipsisIcon, QuestionMarkCircleIcon, XMarkIcon} from '@heroicons/vue/24/outline';
import SidebarMenu from '@/components/SidebarMenu';
import MessageApi from '@/api/MessageApi';
import Form from 'vform';
import Message from '@/resources/Message';
import moment from 'moment';
import {compile, h, createApp, toRaw} from 'vue';

import data from 'emoji-mart-vue-fast/data/apple.json';
import 'emoji-mart-vue-fast/css/emoji-mart.css';
import {Picker, EmojiIndex, Emoji} from 'emoji-mart-vue-fast/src';
import vClickOutside from 'click-outside-vue3'
import ChatTextarea from '@/components/chat/ChatTextarea';

const emojiRegex = require('emoji-regex');
const unicodeSubstring = require('unicode-substring');

let emojiIndex = new EmojiIndex(data);

export default {
  components: {
    ChatTextarea,
    SidebarMenu,
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    XMarkIcon,
    ChatBubbleLeftEllipsisIcon,
    QuestionMarkCircleIcon,
    Picker,
  },

  directives: {
    clickOutside: vClickOutside.directive
  },

  emits: {
    chatToggled: Boolean,
  },

  props: {
    chatOpen: Boolean,
  },

  data() {
    return {
      form: new Form({
        body: '',
      }),
      messages: [],
      emojiIndex: emojiIndex,
      showMobilePicker: false,
      showDesktopPicker: false,
      cursorPosition: 0,
    };
  },

  async created() {
    this.$echo
        .channel('messages')
        .listen('.chat.message.created', (payload) => {
          this.addMessage(payload.message);
        });

    this.messages = await Message.get();
  },

  updated() {
    const chatBoxes = document.querySelectorAll('[aria-label="Message list"]');
    chatBoxes.forEach(chatBox => chatBox.scrollTop = chatBox.scrollHeight);
  },

  methods: {
    transformToHtml(body) {
      return body
        .replace(/\n/g, '<br>')
        .replace(emojiRegex(), (unicodeEmoji) => {
          const emoji = emojiIndex.nativeEmoji(unicodeEmoji);
          if (!emoji) {
            return body;
          }

          return this.emojiToHtml(emoji);
        });
    },

    emojiToHtml(emoji) {
      const style = `background-position: ${emoji.getPosition()}`
      return `<img
                alt="${emoji.native}"
                draggable="false"
                src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                class='emoji-set-apple emoji-type-image border-0 mx-px pointer-events-none inline-block w-6 h-6'
                style="${style}"
              />`;
    },

    handleOutsideMobilePickerClick(event) {
      if (['picker-trigger-mobile', 'picker-trigger-desktop'].includes(event.target.id)) {
        return;
      }

      this.showMobilePicker = false;
    },

    handleOutsideDesktopPickerClick(event) {
      if (['picker-trigger-mobile', 'picker-trigger-desktop'].includes(event.target.id)) {
        return;
      }

      this.showDesktopPicker = false;
    },

    selectEmoji(emoji) {
      const start = unicodeSubstring(this.form.body, 0 , this.cursorPosition);
      const end = unicodeSubstring(this.form.body, this.cursorPosition);

      this.cursorPosition += emoji.native.length / 2;
      this.form.body = start + emoji.native + end;
    },

    handleUpdateCursorPosition(cursorPosition) {
      this.cursorPosition = cursorPosition;
    },

    addMessage(message) {
      this.messages.push(message);
      if (this.messages.length > 50) {
        this.messages = this.messages.slice(-50);
      }
    },

    closeChat() {
      this.$emit('chatToggled', false);
    },

    formatDt(dateTime) {
      return moment(dateTime).format('HH:mm');
    },

    async send() {
      try {
        await MessageApi.store(this.form);
        this.form.reset();
      } catch (e) {
        if (e.response.status === 422 && this.form.errors.has('body')) {
          this.$notify({
            'type': 'error',
            'title': this.form.errors.get('body'),
          });
        } else if (e.response.status === 429) {
          this.$notify({
            'type': 'error',
            'title': 'You spam messages too often',
          });
        }
        console.log(e);
      }
    },
  },
};
</script>

<style scoped>

</style>
