<template>
  <div class="shadow sm:hidden">

    <ul v-if="currencyPaginator.meta.total === 0" role="list" class="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
      <li>
        <p class="block px-4 py-4 bg-white">
          No records found
        </p>
      </li>
    </ul>

    <ul v-else role="list" class="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
      <li v-for="currency in currencyPaginator.data" :key="currency.id">
        <span @click.prevent="activate(currency)" class="block px-4 py-4 bg-white hover:bg-gray-50">
          <span class="flex items-center space-x-4">
            <span class="flex-1 flex space-x-2 truncate">
              <img class="flex-shrink-0 h-5 w-5"
                   :src="require(`@/assets/icons/${currency.symbol}.png`)">
              <span class="flex flex-col text-gray-500 text-sm truncate">
                <span class="truncate">{{ currency.name }}</span>
                <span>
                  <span class="text-gray-900 font-medium">
                    {{ currency.account ? formatCrypto(currency.account.balance) : 0 }}
                  </span>
                  {{ currency.symbol }}
                </span>
              </span>
            </span>

            <ChevronRightIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />

          </span>
        </span>
      </li>
    </ul>

    <ResponsivePagination :paginator="currencyPaginator" @paginate-to="$emit('paginate-to', $event)"></ResponsivePagination>

  </div>
</template>

<script>
  import {
    PlusSmallIcon,
    ChevronRightIcon
  } from '@heroicons/vue/20/solid'
  import CopiesAddress from '@/views/Address/CopiesAddress';
  import ResponsivePagination from '@/components/pagination/ResponsivePagination';
  import FormatsCrypto from '@/mixins/FormatsCrypto'

  export default {
    components: {
      PlusSmallIcon,
      ChevronRightIcon,
      ResponsivePagination,
    },

    mixins: [ CopiesAddress, FormatsCrypto ],

    props: {
      currencyPaginator: Object,
    },

    methods: {
      activate(currency) {
        if (currency.account) {
          this.$router.push({ name: 'accounts.show', params: { accountId: currency.account.id } })
        }
      },
    },
  }
</script>

<style scoped>

</style>
