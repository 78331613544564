<template>
  <div class="hidden sm:block">
    <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex flex-col mt-2">
        <div class="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">

          <table class="min-w-full divide-y divide-gray-200">
            <thead>
            <tr>
              <th class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                User
              </th>
              <th class="hidden px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:table-cell">
                Nonce
              </th>
              <th class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Bet
              </th>
              <th class="hidden px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:table-cell">
                Side
              </th>
              <th class="hidden px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:table-cell">
                Value
              </th>
              <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Reward
              </th>
              <th class="hidden px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider 2xl:table-cell">
                Date
              </th>
            </tr>
            </thead>

            <tbody v-if="paginator.meta.total === 0">
            <tr>
              <td class="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                No records found
              </td>
            </tr>
            </tbody>

            <TransitionGroup tag="tbody" name="list" class="bg-white divide-y divide-gray-200">
              <tr v-for="spinner in paginator.data" :key="spinner.id" class="bg-white">
                <td class="px-6 py-4 text-left whitespace-nowrap text-sm text-gray-500">
                  <img class="h-10 w-10 rounded-full inline-flex" :src="spinner.user.profile_photo_url" alt=""/>
                  <span class="ml-2 font-medium text-gray-500">{{ spinner.user.name }}</span>
                </td>
                <td class="hidden px-6 py-4 text-left whitespace-nowrap text-sm text-gray-500 md:table-cell">
                  <span class="text-gray-500">{{ spinner.nonce }}</span>
                </td>
                <td class="px-6 py-4 text-left whitespace-nowrap text-sm text-gray-500">
                  <span class="text-gray-900 font-medium">{{ formatCrypto(spinner.bet) }}</span> {{ spinner.currency_symbol }}
                </td>
                <td class="hidden px-6 py-4 text-left whitespace-nowrap text-sm text-gray-500 md:table-cell">
                  <Component :is="formatSpinnerSide(spinner.side)" class="h-6 w-6 inline-flex text-cyan-600"/>
                </td>
                <td class="hidden px-6 py-4 text-left whitespace-nowrap text-sm text-gray-500 md:table-cell">
                  <Component :is="formatSpinnerValue(spinner.value)" class="h-6 w-6 inline-flex text-cyan-600"/>
                </td>
                <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                  <span class="font-medium" :class="[ spinner.reward > 0 ? 'text-green-600' : 'text-red-400' ]">
                    {{ formatCrypto(spinner.reward) }}
                  </span> {{ spinner.currency_symbol }}
                </td>
                <td class="hidden px-6 py-4 text-left whitespace-nowrap text-sm text-gray-500 2xl:table-cell">
                  <time :datetime="spinner.created_at">{{ formatDt(spinner.updated_at) }}</time>
                </td>
              </tr>
            </TransitionGroup>
          </table>

          <DesktopPagination :paginator="paginator" @paginate-to="$emit('paginate-to', $event)"></DesktopPagination>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BanknotesIcon, Square2StackIcon } from '@heroicons/vue/24/outline'
import FormatsDt from '@/mixins/FormatsDt'
import DesktopPagination from '@/components/pagination/DesktopPagination'
import FormatsCrypto from '@/mixins/FormatsCrypto'
import FormatsSpinner from '@/mixins/FormatsSpinner'
import { TransitionGroup } from 'vue'

export default {
  components: {
    BanknotesIcon,
    Square2StackIcon,
    DesktopPagination,
    TransitionGroup,
  },

  mixins: [
    FormatsDt,
    FormatsCrypto,
    FormatsSpinner,
  ],

  props: {
    paginator: Object,
  },
}
</script>

<style scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
