import { createStore } from 'vuex'

import auth from "@/store/modules/auth";
import wallet from "@/store/modules/wallet";
import persistentConfig from "@/store/modules/persistent-config";

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth: auth,
    wallet: wallet,
    persistentConfig: persistentConfig,
  }
})
