<template>
  <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
      <div class="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
        <svg class="absolute top-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
          <defs>
            <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="384" fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
        </svg>
        <svg class="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
          <defs>
            <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="384" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
        </svg>
        <svg class="absolute bottom-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
          <defs>
            <pattern id="d3eb07ae-5182-43e6-857d-35c643af9034" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="384" fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
        </svg>
      </div>
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
      <div class="text-lg max-w-prose mx-auto">
        <h1>
          <span class="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">Terms of Service</span>
        </h1>
        <p class="mt-8 text-xl text-gray-500 leading-8">All users of this website must strictly comply with all the rules stated below</p>
      </div>
      <div class="mt-6 prose prose-cyan prose-lg text-gray-500 mx-auto">
        <h2>Responsibility</h2>
        <p>Each user should strictly comply to the law of the country of residence as well as international law. This website is not liable for any illegal actions made by their users</p>
        <p>This website is not liable for any losses caused by ineffective funds management</p>
      </div>
      <div class="mt-6 prose prose-cyan prose-lg text-gray-500 mx-auto">
        <h2>Wallet</h2>
        <p>Users are solely responsible for providing valid addresses for funds sends/withdrawals etc. This website takes all necessary measures to avoid but is not liable for any losses caused by invalid address inputs</p>
      </div>
      <div class="mt-6 prose prose-cyan prose-lg text-gray-500 mx-auto">
        <h2 id="chat">Chat</h2>
        <p>Please follow our rules:</p>
        <ul>
          <li>Do not post ids, promos, referrals, advertisements</li>
          <li>Do not post any links without prior agreement with administration</li>
          <li>Do not try to insult or discriminate anyone in any way</li>
        </ul>
      </div>
      <div class="mt-6 prose prose-cyan prose-lg text-gray-500 mx-auto">
        <h2>General rules</h2>
        <p>Please follow our rules:</p>
        <ul>
          <li>Do not abuse free faucet by creating multiple accounts</li>
          <li>Do not use bots or any other automation tool</li>
          <li>Do not try to gain an unfair advantage to abuse the service</li>
        </ul>
      </div>
      <div class="mt-6 prose prose-cyan prose-lg text-gray-500 mx-auto">
        <h2>Consequences of abusive behaviour</h2>
        <p>All accounts involved in rules violation are subjects to following measures:</p>
        <ul>
          <li>Indefinite suspension</li>
          <li>Balance forfeit without the right to refund</li>
          <li>Indefinite chat mute and/or messages cleaning</li>
        </ul>
      </div>
      <div class="mt-6 prose prose-cyan prose-lg text-gray-500 mx-auto">
        <h2>Terms update</h2>
        <p>All the above mentioned rules are subjects to be improved and changed at any time. Users are solely responsible for checking terms of service on a regular basis</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {

  }
</script>
