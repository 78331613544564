<template>
  <div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="mx-auto w-full max-w-sm lg:w-96 px-4">
      <div>
        <h2 class="mt-6 text-l text-gray-900">
          Forgot your password? No problem. Just let us know your email address and we will email you a password reset link that will allow you to choose a new one.
        </h2>
      </div>

      <div class="mt-8">
        <div class="m t-6">
          <form @submit.prevent="emailResetLink" class="space-y-6">

            <div v-if="success" class="mb-4 font-medium text-sm text-green-600">We have emailed your password reset link!</div>

            <div>
              <label for="email" class="block text-sm font-medium text-gray-700">
                Email
              </label>
              <div class="mt-1">
                <input v-model="form.email"
                  id="email" name="email" type="email" autocomplete="email" required=""
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm"/>
              </div>
              <HasError :form="form" field="email"></HasError>
            </div>

            <div>
              <button type="submit"
                      :class="{ 'opacity-25': form.busy }"
                      :disabled="form.busy"
                      class="w-full justify-center btn-filled">
                Email password reset link
              </button>
            </div>
          </form>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Form } from 'vform';
  import AuthApi from '@/api/AuthApi';
  import { HasError } from 'vform/src/components/tailwind';

  export default {
    components: {
      HasError,
    },

    data() {
      return {
        success: false,
        form: new Form({
          email: '',
        })
      };
    },

    methods: {
      async emailResetLink() {
        this.success = false;

        try {
          await AuthApi.forgotPassword(this.form);
          this.success = true;
        } catch (e) {
          console.log(e);
        }
      }
    }
  }
</script>
