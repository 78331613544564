<template>
  <AuthLayout>
    <VWait for="data">

      <template v-slot:waiting>
        <RadarSpinner/>
      </template>

      <main class="flex-1 relative pb-8 z-0 overflow-y-auto">

        <PageHeader>
          <template #name>Deposit</template>
          <template #control>
            <RouterLink
                :to="{ name: 'accounts.index' }"
                class="btn-default"
            >
              Wallet
            </RouterLink>
          </template>
        </PageHeader>

        <div class="mt-8">

          <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <div>
              <div class="shadow sm:rounded-md sm:overflow-hidden">
                <div class="bg-white py-6 px-4 space-y-6 sm:p-6">

                  <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                      Please send your {{ this.activeCurrency.currency_symbol }} to this address
                    </h3>
                    <p class="mt-1 text-sm text-gray-500">
                      Network confirmations required: {{ this.activeCurrency.confirmations_required }}
                    </p>
                  </div>

                  <div class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                    <div class="space-y-6 sm:space-y-5">

                      <div
                          class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="account" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          Currency
                          <BadgeRequired/>
                        </label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                          <select :value="activeCurrency.currency_id" @input="handleSelect" id="account" autocomplete="account" class="input-text">
                            <option v-for="currency in currencies" :key="currency.currency_id" :value="currency.currency_id">
                              {{ currency.currency_name }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div
                          class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="address" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          Address
                        </label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2 flex rou nded-md shadow-sm sm:max-w-xs">
                          <div class="relative flex flex-grow items-stretch focus-within:z-10">
                            <textarea v-model="activeCurrency.address" rows="2"
                                      class="block w-full rounded-none rounded-l-md border-gray-300 focus:border-cyan-500 focus:ring-cyan-500 text-xs text-center resize-none"/>
                          </div>
                          <button @click="copyAddress" type="button"
                                  class="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-cyan-500 focus:outline-none focus:ring-1 focus:ring-cyan-500">
                            <ClipboardDocumentIcon class="h-5 w-5 text-gray-400" aria-hidden="true"/>
                          </button>
                        </div>
                      </div>

                      <div
                          class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="account" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          QR
                        </label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                          <QrcodeVue :value="activeCurrency.address" :size="150" level="H"/>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>

        </div>
      </main>
    </VWait>
  </AuthLayout>
</template>

<script>
import AuthLayout from '@/components/layouts/AuthLayout';
import PageHeader from '@/components/PageHeader';
import DesktopTable from '@/views/Address/DesktopTable';
import ResponsiveTable from '@/views/Address/ResponsiveTable';
import BadgeRequired from '@/components/BadgeRequired';
import { HasError } from 'vform/src/components/tailwind';
import FormatsCrypto from '@/mixins/FormatsCrypto';
import RadarSpinner from '@/components/RadarSpinner';
import { ClipboardDocumentIcon } from '@heroicons/vue/20/solid';
import { copyText } from 'vue3-clipboard';
import QrcodeVue from 'qrcode.vue';
import { mapGetters, mapState } from 'vuex';

export default {
  components: {
    RadarSpinner,
    BadgeRequired,
    ResponsiveTable,
    DesktopTable,
    PageHeader,
    AuthLayout,
    HasError,
    ClipboardDocumentIcon,
    QrcodeVue,
  },

  mixins: [FormatsCrypto],

  data () {
    return {
      currencyId: null,
    };
  },

  computed: {
    ...mapState({
      currencies: state => state.wallet.currencies,
      activeCurrencyId: state => state.wallet.activeCurrencyId,
    }),

    ...mapGetters('wallet', [
      'activeCurrency',
    ]),
  },

  watch: {
    async currencyId(value) {
      await this.$store.dispatch('wallet/setActiveCurrencyId', value);
    }
  },

  async created () {
    this.$wait.start('data');

    if (this.$route.query.currencyId) {
      await this.$store.dispatch('wallet/setActiveCurrencyId', this.$route.query.currencyId);
    }

    this.currencyId = this.activeCurrencyId;

    this.$wait.end('data');
  },

  methods: {
    async handleSelect(e) {
      await this.$store.dispatch('wallet/setActiveCurrencyId', e.target.value);
    },

    async copyAddress () {
      try {
        await copyText(this.activeCurrency.address, undefined, () => {});
        this.$notify({
          'title': 'Successfully copied!',
        });
      } catch (e) {
        this.$notify({
          'type': 'error',
          'title': 'Oops. Something went wrong. Please copy manually',
        });
      }
    },
  },
};
</script>
