<template>
  <div class="hidden sm:block">
    <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex flex-col mt-2">
        <div class="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead>
            <tr>
              <th class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Currency
              </th>
              <th class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Symbol
              </th>
              <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Balance
              </th>
              <th class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
            </thead>

            <tbody v-if="currencyPaginator.meta.total === 0">]
              <tr>
                <td class="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  No records found
                </td>
              </tr>
            </tbody>

            <tbody v-else class="bg-white divide-y divide-gray-200">
            <tr v-for="currency in currencyPaginator.data" :key="currency.id"
                :class="[currency.account ? 'cursor-pointer hover:text-gray-900': '', 'bg-white text-sm text-gray-500']"
                @click="activate(currency)"
            >
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="flex">
                  <div class="group inline-flex space-x-2">
                    <img class="flex-shrink-0 h-5 w-5"
                         :src="require(`@/assets/icons/${currency.symbol}.png`)"
                    >
                    <p class="">
                      {{ currency.name }}
                    </p>
                  </div>
                </div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
               <span class="inline-flex py-0.5 rounded-full font-medium capitalize">
                 {{ currency.symbol }}
               </span>
              </td>
              <td class="max-w-0 w-full px-6 py-4 text-right whitespace-nowrap">
               <span class="'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize">
                 {{ currency.account ? formatCrypto(currency.account.balance) : 0 }}
               </span>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <RouterLink
                    @click.stop
                    :to="{ name: 'deposit', query: { currencyId: currency.id } }"
                    class="inline-flex items-center px-4 py-1 border border-transparent shadow-sm text-xs font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                >
                  Deposit
                </RouterLink>
                <RouterLink
                    @click.stop
                    :to="{ name: 'withdraw', query: { currencyId: currency.id } }"
                    class="ml-4 inline-flex items-center px-4 py-1 border border-transparent shadow-sm text-xs font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                >
                  Withdraw
                </RouterLink>
              </td>
            </tr>
            </tbody>

          </table>

          <DesktopPagination :paginator="currencyPaginator" @paginate-to="$emit('paginate-to', $event)"></DesktopPagination>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    PlusSmallIcon,
    MinusSmallIcon,
  } from '@heroicons/vue/20/solid'
  import DesktopPagination from '@/components/pagination/DesktopPagination';
  import FormatsCrypto from '@/mixins/FormatsCrypto'

  export default {
    components: {
      PlusSmallIcon,
      MinusSmallIcon,
      DesktopPagination,
    },

    mixins: [
      FormatsCrypto,
    ],

    props: {
      currencyPaginator: Object,
    },

    methods: {
      activate(currency) {
        if (currency.account) {
          this.$router.push({ name: 'accounts.show', params: { accountId: currency.account.id } })
        }
      }
    }
  }
</script>

<style scoped>

</style>
