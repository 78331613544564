<template>
  <AuthLayout>
    <VWait for="init">

      <template v-slot:waiting>
        <RadarSpinner/>
      </template>

      <main class="flex-1 relative pb-8 z-0 overflow-y-auto">

        <PageHeader>
          <template #name>Referee: {{ referee.name }}</template>
          <template #control>
            <RouterLink
                :to="{ name: 'home' }"
                class="btn-default"
            >
              <HomeIcon class="h-4 w-4 text-cyan-600" aria-hidden="true"></HomeIcon>
            </RouterLink>
            <RouterLink
                :to="{ name: 'referrals' }"
                class="btn-filled"
            >
              My Referrals
            </RouterLink>
          </template>
        </PageHeader>

        <div class="mt-8">
          <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="flex flex-col mt-2">
              <div class="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">

                <table class="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Id
                      </th>
                      <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider hidden sm:table-cell">
                        Dice bet
                      </th>
                      <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Referrer reward
                      </th>
                      <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider hidden sm:table-cell">
                        Date
                      </th>
                    </tr>
                  </thead>

                  <tbody v-if="rewardPaginator.meta.total === 0">
                    <tr>
                      <td class="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        No records found
                      </td>
                    </tr>
                  </tbody>

                  <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-for="reward in rewardPaginator.data" :key="reward.id" class="bg-white">
                      <td class="px-6 py-4 text-left whitespace-nowrap text-sm text-gray-500">
                        <span class="font-medium">{{ reward.id }}</span>
                      </td>
                      <td class="hidden sm:table-cell px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                        <span class="font-medium">{{ formatCrypto(reward.dice_bet) }}</span> {{ activeCurrency.currency_symbol }}
                      </td>
                      <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                        <span class="text-green-600 font-medium">{{ formatCrypto(reward.amount) }}</span> {{ activeCurrency.currency_symbol }}
                      </td>
                      <td class="hidden sm:table-cell px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                        <time :datetime="reward.created_at">{{ formatDt(reward.created_at) }}</time>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <DesktopPagination :paginator="rewardPaginator" @paginate-to="handlePagination"></DesktopPagination>

              </div>
            </div>
          </div>
        </div>
      </main>
    </VWait>
  </AuthLayout>
</template>

<script>
import AuthLayout from '@/components/layouts/AuthLayout.vue';
import PageHeader from '@/components/PageHeader.vue';
import RadarSpinner from '@/components/RadarSpinner.vue';
import FormatsCrypto from '@/mixins/FormatsCrypto';
import FormatsDt from '@/mixins/FormatsDt';
import DesktopPagination from '@/components/pagination/DesktopPagination.vue';
import CopiesText from '@/mixins/CopiesText';
import { mapGetters } from 'vuex';
import Referee from '@/resources/Referee';
import ReferrerReward from '@/resources/ReferrerRewards';
import { HomeIcon } from '@heroicons/vue/20/solid';

export default {
  components: {
    RadarSpinner,
    PageHeader,
    AuthLayout,
    DesktopPagination,
    HomeIcon,
  },

  mixins: [FormatsCrypto, FormatsDt, CopiesText],

  data() {
    return {
      referee: null,
      rewardPaginator: null,
    };
  },

  computed: {
    ...mapGetters('wallet', [
      'activeCurrency',
    ]),
  },

  watch: {
    async activeCurrency(activeCurrency) {
      this.$wait.start('reload');

      this.rewardPaginator = await ReferrerReward
          .where('currency_id', activeCurrency.currency_id)
          .where('referee_id', this.referee.id)
          .get();

      this.$wait.end('reload');
    }
  },

  async created() {
    this.$wait.start('init');

    const [referee, rewardPaginator] = await Promise.all([
      Referee.find(this.$route.params.refereeId),
      ReferrerReward
          .where('currency_id', this.activeCurrency.currency_id)
          .where('referee_id', this.$route.params.refereeId)
          .get()
    ]);

    this.referee = referee;
    this.rewardPaginator = rewardPaginator;

    this.$wait.end('init');
  },

  methods: {
    async handlePagination(page) {
      this.$wait.start('pagination');

      this.rewardPaginator = await ReferrerReward
          .where('currency_id', this.activeCurrency.currency_id)
          .where('referee_id', this.referee.id)
          .page(page)
          .get();

      this.$wait.end('pagination');
    },
  },
}
</script>
