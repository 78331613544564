import cookie from 'js-cookie';

const COOKIE_EXPIRY_DAYS = 365;

const AuthService = {
  isLoggedIn() {
    return this.hasToken();
  },

  isNotLoggedIn() {
    return ! this.isLoggedIn();
  },

  logIn(token, remember) {
    this.setToken(token, remember);
  },

  logOut() {
    cookie.remove('token');
  },

  getToken() {
    return cookie.get('token');
  },

  setToken(token, remember) {
    if (remember) {
      cookie.set('token', token, { expires: COOKIE_EXPIRY_DAYS });
    } else {
      cookie.set('token', token);
    }
  },

  hasToken() {
    const token = this.getToken();
    return token !== null && token !== undefined;
  },
};

export default AuthService;
