<template>
  <AuthLayout>
    <VWait :for="['init', 'reload']">

      <template v-slot:waiting>
        <RadarSpinner/>
      </template>

      <main class="flex-1 relative pb-8 z-0 overflow-y-auto">

        <PageHeader>
          <template #name>Home</template>
        </PageHeader>

        <div class="mt-8">

          <StatCards :stats="favouriteStats" />

          <h2 class="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
            Recent activity
          </h2>

          <ResponsiveTable
            @paginate-to="handlePagination"
            :paginator="activityPaginator"
          ></ResponsiveTable>

          <DesktopTable
            @paginate-to="handlePagination"
            :paginator="activityPaginator"
          ></DesktopTable>

        </div>
      </main>
    </VWait>
  </AuthLayout>
</template>

<script>
  import AuthLayout from '@/components/layouts/AuthLayout';
  import PageHeader from '@/components/PageHeader';
  import RadarSpinner from '@/components/RadarSpinner';
  import DesktopTable from '@/views/Home/DesktopTable';
  import ResponsiveTable from '@/views/Home/ResponsiveTable';
  import Activity from '@/resources/Activity';
  import StatCards from '@/views/Home/StatCards';
  import FavouriteStats from '@/resources/FavouriteStats';
  import { mapGetters } from 'vuex';

  export default {
    components: {
      StatCards,
      ResponsiveTable,
      DesktopTable,
      RadarSpinner,
      PageHeader,
      AuthLayout,
    },

    data() {
      return {
        activityPaginator: null,
        favouriteStats: [],
      };
    },

    computed: {
      ...mapGetters('wallet', [
        'activeCurrency',
      ]),
    },

    watch: {
      async activeCurrency(activeCurrency) {
        this.$wait.start('reload');

        this.favouriteStats = await FavouriteStats.where('account_id', activeCurrency.account_id).get();

        this.$wait.end('reload');
      }
    },

    async created() {
      this.$wait.start('init');

      const [activityPaginator, favouriteStats] = await Promise.all([
        Activity.get(),
        FavouriteStats.where('account_id', this.activeCurrency.account_id).get(),
      ]);
      this.activityPaginator = activityPaginator;
      this.favouriteStats = favouriteStats;

      this.$wait.end('init');
    },

    methods: {
      async handlePagination(page) {
        this.$wait.start('reload');

        this.activityPaginator = await Activity.include(['account.currency']).page(page).get();

        this.$wait.end('reload');
      },
    },
  }
</script>
