import axios from 'axios';

const AuthApi = {
  logIn(form) {
    return form.post('/auth/login');
  },

  register(form) {
    return form.post('/auth/register');
  },

  logOut() {
    return axios.post('/auth/logout');
  },

  forgotPassword(form) {
    return form.post('/auth/forgot-password');
  },

  resetPassword(form) {
    return form.post('/auth/reset-password');
  },

  profile() {
    return axios.get('/auth/profile');
  },

  sendEmailVerificationLink(form) {
    return form.post('/auth/email/verification-notification');
  },
};

export default AuthApi;
