<template>
  <AuthLayout>
    <VWait for="init">

      <template v-slot:waiting>
        <RadarSpinner/>
      </template>

      <main class="flex-1 relative pb-8 z-0 overflow-y-auto">

        <PageHeader>
          <template #name>Top referrers</template>
          <template #control>
            <RouterLink
                :to="{ name: 'home' }"
                class="btn-default"
            >
              <HomeIcon class="h-4 w-4 text-cyan-600" aria-hidden="true"></HomeIcon>
            </RouterLink>
            <RouterLink
                :to="{ name: 'referrals' }"
                class="btn-filled"
            >
              My referrals
            </RouterLink>
          </template>
        </PageHeader>

        <div class="mt-8">
          <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="flex flex-col mt-2">
              <div class="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">

                <table class="min-w-full divide-y divide-gray-200">
                  <thead>
                  <tr>
                    <th class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Rank
                    </th>
                    <th class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      User
                    </th>
                    <th class="hidden px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider md:table-cell">
                      Referrals count
                    </th>
                    <th class="hidden px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider md:table-cell">
                      Referrals total reward
                    </th>
                  </tr>
                  </thead>

                  <tbody v-if="leaderboardPaginator.meta.total === 0">
                    <tr>
                      <td class="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        No records found
                      </td>
                    </tr>
                  </tbody>

                  <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-for="(referrer, index) in leaderboardPaginator.data" :key="referrer.id" class="bg-white">
                      <td class="px-6 py-4 text-left whitespace-nowrap text-sm text-gray-500">
                        <div class="quiz-medal">
                          <div
                              class="quiz-medal__circle "
                              :class="findClass(index + 1)">
                                <span>
                                  {{ index + 1 }}
                                </span>
                          </div>
                          <div class="quiz-medal__ribbon quiz-medal__ribbon--left"></div>
                          <div class="quiz-medal__ribbon quiz-medal__ribbon--right"></div>
                        </div>
                      </td>
                      <td class="px-6 py-4 text-left whitespace-nowrap text-sm text-gray-500">
                        <img class="h-10 w-10 rounded-full inline-flex" :src="referrer.profile_photo_url" alt=""/>
                        <span class="ml-2 font-medium text-gray-500">{{ referrer.name }}</span>
                      </td>
                      <td class="hidden px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500 md:table-cell">
                        <span class="">{{ referrer.referees_count }}</span>
                      </td>
                      <td class="hidden px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500 md:table-cell">
                        <span class="text-green-600">{{ formatCrypto(referrer.total_referrer_reward) }} {{ activeCurrency.currency_symbol }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <DesktopPagination :paginator="leaderboardPaginator" @paginate-to="handlePagination"></DesktopPagination>

              </div>
            </div>
          </div>
        </div>
      </main>
    </VWait>
  </AuthLayout>
</template>

<script>
import AuthLayout from '@/components/layouts/AuthLayout.vue';
import PageHeader from '@/components/PageHeader.vue';
import RadarSpinner from '@/components/RadarSpinner.vue';
import FormatsCrypto from '@/mixins/FormatsCrypto';
import FormatsDt from '@/mixins/FormatsDt';
import DesktopPagination from '@/components/pagination/DesktopPagination.vue';
import CopiesText from '@/mixins/CopiesText';
import { mapGetters } from 'vuex';
import { HomeIcon } from '@heroicons/vue/20/solid';
import ReferrerLeaderboard from '@/resources/ReferrerLeaderboard';

export default {
  components: {
    RadarSpinner,
    PageHeader,
    AuthLayout,
    DesktopPagination,
    HomeIcon,
  },

  mixins: [FormatsCrypto, FormatsDt, CopiesText],

  data() {
    return {
      leaderboardPaginator: null,
    };
  },

  computed: {
    ...mapGetters('wallet', [
      'activeCurrency',
    ]),
  },

  watch: {
    async activeCurrency(activeCurrency) {
      this.$wait.start('reload');

      this.leaderboardPaginator = await ReferrerLeaderboard.where('currency_id', activeCurrency.currency_id).get();

      this.$wait.end('reload');
    }
  },

  async created() {
    this.$wait.start('init');

    this.leaderboardPaginator = await ReferrerLeaderboard.where('currency_id', this.activeCurrency.currency_id).get();

    this.$wait.end('init');
  },

  methods: {
    findClass(rank) {
      switch (rank) {
        case 1:
          return 'gold';
        case 2:
          return 'silver';
        case 3:
          return 'bronze';
        default:
          return 'all';
      }
    }
  }
}
</script>
