<template>
  <RouterView/>
  <NotificationWrapper/>
</template>

<script>
import NotificationWrapper from '@/components/NotificationWrapper';

export default {
  components: {
    NotificationWrapper,
  },
}
</script>

<style>

</style>
