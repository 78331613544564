<template>
  <TransitionRoot as="template" :show="sidebarOpen">
    <Dialog as="div" class="fixed inset-0 flex z-40 lg:hidden" @close="closeSidebar">
      <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
        <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
      </TransitionChild>
      <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
        <div class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-cyan-700">
          <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
            <div class="absolute top-0 right-0 -mr-12 pt-2">
              <button type="button" class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="closeSidebar">
                <span class="sr-only">Close sidebar</span>
                <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
              </button>
            </div>
          </TransitionChild>
          <RouterLink :to="{ name: 'login' }" class="flex-shrink-0 flex items-center px-4">
            <img class="h-8 w-auto" src="/img/logo-cyan-300.svg" alt="playcet"/>
            <span class="ml-2 text-2xl font-bold text-white">Playcet<span class="font-thin text-xs">beta</span></span>
          </RouterLink>
          <nav class="mt-5 flex-shrink-0 h-full divide-y divide-cyan-800 overflow-y-auto" aria-label="Sidebar">
            <div class="px-2 space-y-1">
              <RouterLink v-for="item in navigation" :key="item.name" :to="{ 'name': item.routeName }"
                           :class="[item.current ? 'bg-cyan-800 text-white' : 'text-cyan-100 hover:text-white hover:bg-cyan-600', 'group flex items-center px-2 py-2 text-base font-medium rounded-md']"
                           :aria-current="item.current ? 'page' : undefined"
              >
                <component :is="item.icon" class="mr-4 flex-shrink-0 h-6 w-6 text-cyan-200" aria-hidden="true" />
                {{ item.name }}
              </RouterLink>
            </div>
            <div class="mt-6 pt-6">
              <div class="px-2 space-y-1">
                <RouterLink v-for="item in secondaryNavigation" :key="item.name" :to="{ 'name': item.routeName }"
                             class="text-cyan-100 hover:text-white hover:bg-cyan-600 group flex items-center px-2 py-2 text-base font-medium rounded-md text-cyan-100 hover:text-white hover:bg-cyan-600"
                >
                  <component :is="item.icon" class="mr-4 h-6 w-6 text-cyan-200" aria-hidden="true" />
                  {{ item.name }}
                </RouterLink>
              </div>
            </div>
          </nav>
        </div>
      </TransitionChild>
      <div class="flex-shrink-0 w-14" aria-hidden="true">
        <!-- Dummy element to force sidebar to shrink to fit close icon -->
      </div>
    </Dialog>
  </TransitionRoot>

  <!-- Static sidebar for desktop -->
  <div class="hidden lg:flex lg:flex-shrink-0">
    <div class="flex flex-col w-64">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex flex-col flex-grow bg-cyan-700 pt-5 pb-4 overflow-y-auto">
        <RouterLink :to="{ name: 'home' }" class="flex items-center flex-shrink-0 px-4">
          <img class="h-8 w-auto" src="/img/logo-cyan-300.svg" alt="playcet"/>
          <span class="ml-2 text-2xl font-bold text-white">Playcet<span class="font-thin text-xs">beta</span></span>
        </RouterLink>
        <nav class="mt-5 flex-1 flex flex-col divide-y divide-cyan-800 overflow-y-auto" aria-label="Sidebar">
          <div class="px-2 space-y-1">
            <RouterLink v-for="item in navigation" :key="item.name" :to="{ 'name': item.routeName }"
                         :class="[item.current ? 'bg-cyan-800 text-white' : 'text-cyan-100 hover:text-white hover:bg-cyan-600', 'group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md']"
                         :aria-current="item.current ? 'page' : undefined"
            >
              <component :is="item.icon" class="mr-4 flex-shrink-0 h-6 w-6 text-cyan-200" aria-hidden="true" />
              {{ item.name }}
            </RouterLink>
          </div>
          <div class="mt-6 pt-6">
            <div class="px-2 space-y-1">
              <RouterLink v-for="item in secondaryNavigation" :key="item.name" :to="{ 'name': item.routeName }"
                           class="text-cyan-100 hover:text-white hover:bg-cyan-600 group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"
              >
                <component :is="item.icon" class="mr-4 h-6 w-6 text-cyan-200" aria-hidden="true" />
                {{ item.name }}
              </RouterLink>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
  import { Dialog, DialogOverlay, TransitionChild, TransitionRoot, } from '@headlessui/vue'
  import {
    HomeIcon,
    QuestionMarkCircleIcon,
    ShieldCheckIcon,
    XMarkIcon,
    BanknotesIcon,
    Square2StackIcon,
    CreditCardIcon,
    DocumentTextIcon,
    ChartBarIcon,
    ArrowPathIcon,
    UserGroupIcon,
  } from '@heroicons/vue/24/outline'
  import SidebarMenu from '@/components/SidebarMenu';
  import { useRoute } from 'vue-router';

  const navigation = [
    { name: 'Home', routeName: 'home', icon: HomeIcon },
    // { name: 'Referrals', routeName: 'referrals', icon: UserGroupIcon },
    // { name: 'Dice', routeName: 'dice', icon: Square2StackIcon },
    // { name: 'Spinner', routeName: 'spinner.create', icon: ArrowPathIcon },
    { name: 'Wallet', routeName: 'accounts.index', icon: CreditCardIcon },
    // { name: 'Faucet', routeName: 'faucet', icon: BanknotesIcon },
    // { name: 'Leaderboard', routeName: 'dice.leaderboard', icon: ChartBarIcon },
  ];

  const secondaryNavigation = [
    { name: 'FAQ', routeName: 'faq', icon: QuestionMarkCircleIcon },
    { name: 'Terms of Service', routeName: 'terms-of-service', icon: DocumentTextIcon },
    { name: 'Privacy', routeName: 'privacy-policy', icon: ShieldCheckIcon },
  ];

  export default {
    components: {
      SidebarMenu,
      Dialog,
      DialogOverlay,
      TransitionChild,
      TransitionRoot,
      XMarkIcon,
    },

    props: {
      sidebarOpen: Boolean,
    },

    emits: {
      sidebarToggled: Boolean,
    },

    setup() {
      const route = useRoute();
      navigation.forEach(item => {
        item.current = item.routeName === route.name;
      })

      return {
        navigation,
        secondaryNavigation,
      }
    },

    methods: {
      closeSidebar() {
        this.$emit('sidebarToggled', false);
      },
    },

  }
</script>

<style scoped>

</style>
