export default {
  methods: {
    formatCrypto(value) {
      if (value === null) {
        return '0';
      }

      if (parseFloat(value) === 0) {
        return '0';
      }

      const last = [...value].reverse().findIndex(char => char !== '0');
      value = value.substring(0, value.length - last);

      if (value.endsWith('.')) {
        value = value.slice(0, -1);
      }

      return value;
    },
  },
};
