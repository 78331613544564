<template>
  <AuthLayout>
    <VWait for="data">

      <template v-slot:waiting>
        <RadarSpinner/>
      </template>
      <main class="flex-1 relative pb-8 z-0 overflow-y-auto">

        <PageHeader>
          <template #name>FAQ</template>
          -
          <template #control>
            <RouterLink
                :to="{ name: 'home' }"
                class="btn-default"
            >
              Home
            </RouterLink>
          </template>
        </PageHeader>

        <div class="mt-8">
          <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">

            <div class="shadow sm:rounded-md sm:overflow-hidden">
              <div class="bg-white py-6 px-4 space-y-6 sm:p-6">

                <dl class="space-y-6 divide-y divide-gray-200">
                  <Disclosure as="div" v-slot="{ open }">
                    <dt class="text-lg">
                      <DisclosureButton class="text-left w-full flex justify-between items-start text-gray-400">
                        <span class="font-medium text-gray-800">
                          Where can I find my referral link?
                        </span>
                        <span class="ml-6 h-7 flex items-center">
                          <ChevronDownIcon :class="[open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform']" aria-hidden="true"/>
                        </span>
                      </DisclosureButton>
                    </dt>
                    <DisclosurePanel as="dd" class="mt-2 pr-12">
                      <p class="text-base text-gray-500">
                        You may find it here:
                        <RouterLink :to="{ name: 'profile' }" class="underline">
                          Profile
                        </RouterLink>
                      </p>
                    </DisclosurePanel>
                  </Disclosure>

                  <Disclosure as="div" v-slot="{ open }" class="pt-6">
                    <dt class="text-lg">
                      <DisclosureButton class="text-left w-full flex justify-between items-start text-gray-400">
                        <span class="font-medium text-gray-800">
                          Where/When can I withdraw my money?
                        </span>
                        <span class="ml-6 h-7 flex items-center">
                          <ChevronDownIcon :class="[open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform']" aria-hidden="true"/>
                        </span>
                      </DisclosureButton>
                    </dt>
                    <DisclosurePanel as="dd" class="mt-2 pr-12">
                      <p class="text-base text-gray-500">
                        You may do it here: <RouterLink :to="{ name: 'accounts.index' }" class="underline">Wallet</RouterLink>.
                      </p>
                    </DisclosurePanel>
                  </Disclosure>

                  <Disclosure as="div" v-slot="{ open }" class="pt-6">
                    <dt class="text-lg">
                      <DisclosureButton class="text-left w-full flex justify-between items-start text-gray-400">
                        <span class="font-medium text-gray-800">
                           How do you calculate provably fair roll value?
                        </span>
                        <span class="ml-6 h-7 flex items-center">
                          <ChevronDownIcon :class="[open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform']" aria-hidden="true"/>
                        </span>
                      </DisclosureButton>
                    </dt>
                    <DisclosurePanel as="dd" class="mt-2 pr-12">
                      <ul class="text-base text-gray-500">
                        <li>1. Create server seed string: NONCE:SERVER_SEED:NONCE</li>
                        <li>2. Create client seed string: NONCE:CLIENT_SEED:NONCE</li>
                        <li>3. Hash server seed string, using HMAC-SHA512 and client seed string as key</li>
                        <li>4. Take first 8 characters of the above string. Convert to decimal</li>
                        <li>5. Divide decimal by 429496.7295 and round to the nearest whole number</li>
                        <li>6. The value derived is a roll value</li>
                      </ul>
                    </DisclosurePanel>
                  </Disclosure>

                  <Disclosure as="div" v-slot="{ open }" class="pt-6">
                    <dt class="text-lg">
                      <DisclosureButton class="text-left w-full flex justify-between items-start text-gray-400">
                        <span class="font-medium text-gray-800">
                          How do I refer somebody?
                        </span>
                        <span class="ml-6 h-7 flex items-center">
                          <ChevronDownIcon :class="[open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform']" aria-hidden="true"/>
                        </span>
                      </DisclosureButton>
                    </dt>
                    <DisclosurePanel as="dd" class="mt-2 pr-12">
                      <p class="text-base text-gray-500">
                        Share your referral link to your friends. Ask them to sign up using this link.
                        You will get 50% of their faucet income automatically forever.
                        Nothing will be deducted from their account. Both of you get a profit
                      </p>
                    </DisclosurePanel>
                  </Disclosure>

                  <Disclosure as="div" v-slot="{ open }" class="pt-6">
                    <dt class="text-lg">
                      <DisclosureButton class="text-left w-full flex justify-between items-start text-gray-400">
                        <span class="font-medium text-gray-800">
                          How can I contact you?
                        </span>
                        <span class="ml-6 h-7 flex items-center">
                          <ChevronDownIcon :class="[open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform']" aria-hidden="true"/>
                        </span>
                      </DisclosureButton>
                    </dt>
                    <DisclosurePanel as="dd" class="mt-2 pr-12">
                      <p class="text-base text-gray-500">
                        You may do it here:
                        <a href="mailto:admin@chia-faucet.com" class="hover:underline">admin@chia-faucet.com</a>
                      </p>
                    </DisclosurePanel>
                  </Disclosure>
                </dl>


              </div>
            </div>

          </div>
        </div>

      </main>
    </VWait>

  </AuthLayout>
</template>

<script>
import AuthLayout from '@/components/layouts/AuthLayout';
import PageHeader from '@/components/PageHeader';
import RadarSpinner from '@/components/RadarSpinner';
import {Disclosure, DisclosureButton, DisclosurePanel} from '@headlessui/vue';
import {ChevronDownIcon} from '@heroicons/vue/24/outline';
import {mapGetters} from 'vuex';
import FormatsCrypto from '@/mixins/FormatsCrypto';

export default {
  components: {
    PageHeader,
    AuthLayout,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronDownIcon,
  },

  mixins: [FormatsCrypto],
}
</script>
