<template>
  <img class="h-6 w-auto inline-flex" src="/img/logo-cyan-700.svg" alt="playcet"/>
</template>

<script>
export default {
  name: 'PlaycetIcon',
}
</script>

<style scoped>

</style>