<template>
  <AuthLayout>
    <VWait for="data">

      <template v-slot:waiting>
        <RadarSpinner/>
      </template>

      <main class="flex-1 relative pb-8 z-0 overflow-y-auto">

        <PageHeader>
          <template #name>Wallet</template>
          <template #control>
            <RouterLink
                :to="{ name: 'deposit' }"
                class="btn-filled"
            >
              Deposit
            </RouterLink>
            <RouterLink
                :to="{ name: 'withdraw' }"
                class="btn-filled"
            >
              Withdraw
            </RouterLink>
          </template>
        </PageHeader>

        <div class="mt-8">

          <h2 class="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
            Overview
          </h2>

          <ResponsiveTable :currency-paginator="currencyPaginator"></ResponsiveTable>

          <DesktopTable :currency-paginator="currencyPaginator"></DesktopTable>
        </div>
      </main>
    </VWait>
  </AuthLayout>
</template>

<script>
  import AuthLayout from '@/components/layouts/AuthLayout';
  import PageHeader from '@/components/PageHeader';
  import ResponsiveTable from '@/views/Account/ResponsiveTable';
  import DesktopTable from '@/views/Account/DesktopTable';
  import Currency from '@/resources/Currency';
  import RadarSpinner from '@/components/RadarSpinner';

  export default {
    components: {
      ResponsiveTable,
      DesktopTable,
      PageHeader,
      AuthLayout,
      RadarSpinner,
    },

    data() {
      return {
        account: null,
        currencyPaginator: [],
      }
    },

    async created() {
      this.$wait.start('data');

      this.currencyPaginator = await Currency.include('account').get();

      this.$wait.end('data');
    },

    methods: {
      async handlePagination(page) {
        this.$wait.start('data');

        this.currencyPaginator = await Currency.include('account').page(page).get();

        this.$wait.end('data');
      },
    },
  }
</script>
