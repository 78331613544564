import axios from 'axios';
import AuthService from '@/service/AuthService';
import store from '@/store';
import router from '@/router';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.interceptors.request.use((config) => {
  if (AuthService.isLoggedIn()) {
    config.headers = {
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + AuthService.getToken(),
    };
  }

  return config;
})

axios.interceptors.response.use(async response => response, error => {
  const { status } = error.response;

  if (status === 401 && AuthService.isLoggedIn()) {
    store.dispatch('auth/logOut');
    return router.push({ name: 'login' });
  }

  if (status === 503) {
    return router.push({ name: 'maintenance-mode' });
  }

  if (status >= 500) {
    return router.push({ name: 'server-error' });
  }

  return Promise.reject(error)
})
