import { createRouter, createWebHistory } from 'vue-router'
import AuthLogin from '@/views/Auth/AuthLogin';
import guest from '@/middleware/guest';
import AuthRegister from '@/views/Auth/AuthRegister';
import ForgotPassword from '@/views/Auth/ForgotPassword';
import ResetPassword from '@/views/Auth/ResetPassword';
import TermsOfService from '@/views/TermsOfService';
import PrivacyPolicy from '@/views/PrivacyPolicy';
import ServerError from '@/views/ServerError';
import NotFound from '@/views/NotFound';
import auth from '@/middleware/auth';
import EmailVerification from '@/views/Auth/EmailVerification';
import EmailVerify from '@/views/Auth/EmailVerify';
import HomeIndex from '@/views/Home/HomeIndex';
import UserProfile from '@/views/Auth/UserProfile';
import DiceCreate from '@/views/Dice/DiceCreate.vue';
import FaucetCreate from '@/views/Faucet/FaucetCreate.vue';
import store from '@/store';
import middlewarePipeline from '@/router/middlewarePipeline';
import PostIndex from '@/views/Posts/PostIndex';
import PostShow from '@/views/Posts/PostShow';
import FaqIndex from '@/views/FaqIndex';
import FaucetIndex from '@/views/Faucet/FaucetIndex';
import DiceIndex from '@/views/Dice/DiceIndex';
import LandingPage from '@/views/Landing/LandingPage';
import verified from '@/middleware/verified';
import ReferralIndex from '@/views/Referral/ReferralIndex.vue';
import AccountShow from '@/views/Account/AccountShow';
import AccountIndex from '@/views/Account/AccountIndex';
import CreateWithdraw from "@/views/Wallet/CreateWithdraw";
import CreateDeposit from "@/views/Wallet/CreateDeposit";
import MaintenanceMode from "@/views/MaintenanceMode";
import DiceLeaderboard from '@/views/Dice/DiceLeaderboard.vue';
import RefereeShow from '@/views/Referral/RefereeShow.vue';
import ReferralLeaderboard from '@/views/Referral/ReferralLeaderboard.vue';
import SpinnerLeaderboard from '@/views/Spinner/SpinnerLeaderboard.vue';
import SpinnerIndex from '@/views/Spinner/SpinnerIndex.vue';
import SpinnerCreate from '@/views/Spinner/SpinnerCreate.vue';

const routes = [
  {
    path: '/',
    name: 'landing',
    meta: { middleware: [guest] },
    component: LandingPage,
  },

  {
    path: '/login',
    name: 'login',
    meta: { middleware: [guest] },
    component: AuthLogin,
  },
  {
    path: '/register',
    name: 'register',
    meta: { middleware: [guest] },
    component: AuthRegister,
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    meta: { middleware: [guest] },
    component: ForgotPassword,
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password',
    meta: { middleware: [guest] },
    component: ResetPassword,
  },

  {
    path: '/email/verification',
    name: 'email.verification',
    meta: { middleware: [auth] },
    component: EmailVerification,
  },
  {
    path: '/email/verify',
    name: 'email.verify',
    meta: { middleware: [auth] },
    component: EmailVerify,
  },

  {
    path: '/faucet',
    name: 'faucet',
    meta: { middleware: [auth, verified] },
    component: FaucetCreate,
  },
  {
    path: '/faucet/index',
    name: 'faucet.index',
    meta: { middleware: [auth, verified] },
    component: FaucetIndex,
  },

  {
    path: '/dice',
    name: 'dice',
    meta: { middleware: [auth] },
    component: DiceCreate,
  },
  {
    path: '/dice/index',
    name: 'dice.index',
    meta: { middleware: [auth] },
    component: DiceIndex,
  },
  {
    path: '/dice/leaderboard',
    name: 'dice.leaderboard',
    meta: { middleware: [auth] },
    component: DiceLeaderboard,
  },

  {
    path: '/spinner',
    name: 'spinner.create',
    meta: { middleware: [auth] },
    component: SpinnerCreate,
  },
  {
    path: '/spinner/index',
    name: 'spinner.index',
    meta: { middleware: [auth] },
    component: SpinnerIndex,
  },
  {
    path: '/spinner/leaderboard',
    name: 'spinner.leaderboard',
    meta: { middleware: [auth] },
    component: SpinnerLeaderboard,
  },

  {
    path: '/profile',
    name: 'profile',
    meta: { middleware: [auth] },
    component: UserProfile,
  },

  {
    path: '/referrals',
    name: 'referrals',
    meta: { middleware: [auth] },
    component: ReferralIndex,
  },
  {
    path: '/referees/:refereeId',
    name: 'referees.show',
    meta: { middleware: [auth] },
    component: RefereeShow,
  },
  {
    path: '/referrals/leaderboard',
    name: 'referrals.leaderboard',
    meta: { middleware: [auth] },
    component: ReferralLeaderboard,
  },

  {
    path: '/home',
    name: 'home',
    meta: { middleware: [auth] },
    component: HomeIndex,
  },

  {
    path: '/wallet',
    name: 'accounts.index',
    meta: { middleware: [auth] },
    component: AccountIndex,
  },
  {
    path: '/accounts/:accountId',
    name: 'accounts.show',
    meta: { middleware: [auth] },
    component: AccountShow,
  },

  {
    path: '/deposit',
    name: 'deposit',
    meta: { middleware: [auth] },
    component: CreateDeposit,
  },

  {
    path: '/withdraw',
    name: 'withdraw',
    meta: { middleware: [auth] },
    component: CreateWithdraw,
  },

  {
    path: '/posts',
    name: 'posts.index',
    meta: { middleware: [auth] },
    component: PostIndex,
  },
  {
    path: '/posts/:postId',
    name: 'posts.show',
    meta: { middleware: [auth] },
    component: PostShow,
  },

  {
    path: '/faq',
    name: 'faq',
    meta: { middleware: [auth] },
    component: FaqIndex,
  },
  {
    path: '/terms-of-service',
    name: 'terms-of-service',
    component: TermsOfService,
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicy,
  },
  {
    path: '/maintenance-mode',
    name: 'maintenance-mode',
    component: MaintenanceMode
  },
  {
    path: '/server-error',
    name: 'server-error',
    component: ServerError
  },
  {
    path: "/:catchAll(.*)",
    name: 'not-found',
    component: NotFound
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
  },
  routes
});

router.beforeEach((to, from, next) => {
  const middleware = to.meta.middleware;
  const context = { to, from, next, store };

  if (! middleware) {
    return next();
  }

  middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router
