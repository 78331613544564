import { copyText } from 'vue3-clipboard';

export default {
  methods: {
    async copyAddress(value) {
      try {
        await copyText(value, undefined, () => {});
        this.$notify({ title: 'Successfully copied!', text: value })
      } catch (e) {
        console.log(e);
      }
    },
  },
}
