<template>
  <div
      id="comment"
      contenteditable="true"
      @blur="handleBlur"
      @input="handleInput"
      @click="handleClick"
      @paste.prevent="handlePaste"
      class="shadow-sm block w-full h-20 px-2 py-3 focus:outline-cyan-500 sm:text-sm border border-gray-300 rounded-md resize-none overflow-auto whitespace-pre-wrap"
      placeholder="Add a message"
      @keydown.enter.exact.prevent="handleEnter"
      @keydown.enter.shift.exact.prevent="handleShiftEnter"
      v-html="parsedText"
  ></div>
</template>

<script>
import emojiRegex from 'emoji-regex';
import {EmojiIndex} from 'emoji-mart-vue-fast/src';
import data from 'emoji-mart-vue-fast/data/apple.json';

let emojiIndex = new EmojiIndex(data);

export default {
  name: 'ChatTextarea',

  emits: [
      'update:modelValue',
      'update:cursorPosition',
  ],

  props: {
    modelValue: {
      type: String,
      require: true,
      default: '',
    },
  },

  computed: {
    parsedText() {
      return this.transformToHtml(this.modelValue);
    },
  },

  methods: {
    handleEnter(e) {
      if (this.$isMobile()) {
        this.handleShiftEnter();
        return;
      }
      this.updateModelValue(e);
      this.$emit('enter');
    },

    handlePaste(e) {
      let data = e.clipboardData.getData('text/plain');
      if (data) {
        const strippedData = data.replace(/<\/?[^`]+?\/?>/gmi, '');
        const temp = document.createElement('div');
        temp.innerHTML = strippedData;
        document.execCommand('insertHTML', false, temp.textContent);
      }
    },

    handleShiftEnter() {
      document.execCommand('insertLineBreak');
    },

    handleBlur(e) {
      this.updateModelValue(e);
    },

    handleInput() {
      this.updateCursorPosition();
    },

    updateModelValue(e) {
      const modelValue = this.transformTags(e.target.innerHTML);
      this.$emit('update:modelValue', modelValue);
      this.updateCursorPosition();
    },

    handleClick() {
      this.updateCursorPosition();
    },

    updateCursorPosition() {
      const node = document.querySelector('#comment');
      const range = window.getSelection().getRangeAt(0);

      const treeWalker = document.createTreeWalker(
          node,
          NodeFilter.ELEMENT_NODE,
          function(node) {
            const nodeRange = document.createRange();
            nodeRange.selectNodeContents(node);
            return nodeRange.compareBoundaryPoints(Range.END_TO_END, range) < 1 ?
                NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_REJECT;
          },
          false
      );

      let charCount = 0;
      let lastNodeLength = 0;

      if (range.startContainer.nodeType === 3) {
        charCount += range.startOffset;
      }

      while (treeWalker.nextNode()) {
        charCount += lastNodeLength;
        lastNodeLength = 0;

        if(range.startContainer !== treeWalker.currentNode) {
          if(treeWalker.currentNode instanceof Text) {
            lastNodeLength += treeWalker.currentNode.length;
          } else if(
              treeWalker.currentNode instanceof HTMLBRElement ||
              treeWalker.currentNode instanceof HTMLImageElement
          ) {
            lastNodeLength++;
          }
        }
      }

      const position = charCount + lastNodeLength;
      this.$emit('update:cursorPosition', position);
    },

    transformToHtml(body) {
      return body
        .replace(/\n/g, '<br>')
        .replace(emojiRegex(), (unicodeEmoji) => {
          const emoji = emojiIndex.nativeEmoji(unicodeEmoji);
          if (!emoji) {
            return body;
          }

          return this.emojiToHtml(emoji);
        });
    },

    emojiToHtml(emoji) {
      const style = `background-position: ${emoji.getPosition()}`
      return `<img
                alt="${emoji.native}"
                draggable="false"
                src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                class='emoji-set-apple emoji-type-image border-0 mx-px pointer-events-none inline-block w-6 h-6'
                style="${style}"
              />`;
    },

    transformTags(content) {
      const imgRegex = /<img.*?src=\"(.*?)\"[^\\>]+>/g;
      const brRegex = /<br\s*[\/]?>/g;

      return content
        .replace(imgRegex, (imgString) => {
          const imgElement = new DOMParser().parseFromString(imgString, "text/html").body.firstElementChild;
          return imgElement.alt;
        })
        .replace(brRegex, '\n');
    },
  },
};
</script>

<style scoped>
  #comment[placeholder]:empty:before {
    content: attr(placeholder);
    color: #718096;
  }
</style>
