<template>
  <div class="hidden sm:block">
    <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex flex-col mt-2">
        <div class="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">

          <table class="min-w-full divide-y divide-gray-200">
            <thead>
            <tr>
              <th class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Type
              </th>
              <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Amount
              </th>
              <th class="hidden px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:table-cell">
                Status
              </th>
              <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Date
              </th>
            </tr>
            </thead>

            <tbody v-if="paginator.meta.total === 0">
              <tr>
                <td class="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  No records found
                </td>
              </tr>
            </tbody>

            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="activity in paginator.data" :key="activity.id" class="bg-white">
                <td class="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  <div class="flex">
                    <a href="#" class="group inline-flex space-x-2 truncate text-sm">
                      <BanknotesIcon v-if="activity.type === 'Faucet'" class="flex-shrink-0 h-5 w-5 text-gray-500 group-hover:text-gray-900" aria-hidden="true" />
                      <Square2StackIcon v-if="activity.type === 'Dice'" class="flex-shrink-0 h-5 w-5 text-gray-500 group-hover:text-gray-900" aria-hidden="true" />
                      <ArrowRightOnRectangleIcon v-if="activity.type === 'Withdraw'" class="flex-shrink-0 h-5 w-5 text-gray-500 group-hover:text-gray-900" aria-hidden="true" />
                      <ArrowLeftOnRectangleIcon v-if="activity.type === 'Deposit'" class="flex-shrink-0 h-5 w-5 text-gray-500 group-hover:text-gray-900" aria-hidden="true" />
                      <UsersIcon v-if="activity.type === 'Doubler'" class="flex-shrink-0 h-5 w-5 text-gray-500 group-hover:text-gray-900" aria-hidden="true" />
                      <p class="text-gray-500 truncate group-hover:text-gray-900">
                        {{ activity.type }}
                      </p>
                    </a>
                  </div>
                </td>
                <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                  <span class="text-gray-900 font-medium">{{ formatCrypto(activity.amount) }} </span> {{ activity.currency_symbol }}
                </td>
                <td class="hidden px-6 py-4 whitespace-nowrap text-sm text-gray-500 md:table-cell">
                  <span :class="[statusStyles[activity.status], 'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize']">
                    {{ activity.status.toLowerCase() }}
                  </span>
                </td>
                <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                  <time :datetime="activity.created_at">{{ formatDt(activity.created_at) }}</time>
                </td>
              </tr>
            </tbody>
          </table>

          <DesktopPagination :paginator="paginator" @paginate-to="$emit('paginate-to', $event)"></DesktopPagination>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    BanknotesIcon,
    Square2StackIcon,
    ArrowRightOnRectangleIcon,
    ArrowLeftOnRectangleIcon,
    UsersIcon,
  } from '@heroicons/vue/24/outline'
  import FormatsDt from '@/mixins/FormatsDt';
  import DesktopPagination from '@/components/pagination/DesktopPagination';
  import FormatsCrypto from '@/mixins/FormatsCrypto';
  import HasStatusStyles from '@/mixins/HasStatusStyles';

  export default {
    components: {
      BanknotesIcon,
      Square2StackIcon,
      DesktopPagination,
      ArrowRightOnRectangleIcon,
      ArrowLeftOnRectangleIcon,
      UsersIcon,
    },

    mixins: [
      HasStatusStyles,
      FormatsDt,
      FormatsCrypto,
    ],

    props: {
      paginator: Object,
    },
  }
</script>

<style scoped>

</style>
