<template>
  <div class="bg-white">
    <main>
      <!-- Hero section -->
      <div class="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-48">
        <div class="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
          <div>
            <div class="flex-shrink-0 flex items-center px-4">
              <img class="h-11 w-auto" src="/img/logo-cyan-700.svg" alt="playcet"/><span class="ml-2 text-3xl font-bold text-cyan-700">Playcet</span>
            </div>
            <div class="mt-20">
              <div>
                <a href="#roadmap" class="inline-flex space-x-4">
                  <span class="inline-flex rounded bg-cyan-50 px-2.5 text-xs font-semibold text-cyan-500 tracking-wide uppercase items-center">
                    <span>Check the roadmap</span>
                    <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
                  </span>
                </a>
              </div>
              <div class="mt-6 sm:max-w-xl">
                <h1 class="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Blockchain gaming platform</h1>
                <p class="mt-6 text-xl text-gray-500">Let's earn some crypto together</p>
              </div>
              <div class="mt-12 sm:max-w-lg sm:w-full sm:flex">
                <div class="min-w-0">
                  <RouterLink :to="{ name: 'register' }" class="btn-filled sm:px-10 px-5 py-3 block w-full text-center">Sign up</RouterLink>
                </div>
                <div class="mt-4 sm:mt-0 sm:ml-3">
                  <RouterLink :to="{ name: 'login' }" type="submit" class="btn-default sm:px-10 px-5 py-3 block w-full text-center">Sign in</RouterLink>
                </div>
              </div>
              <div class="mt-6">
                <div class="min-w-0 flex-1 py-1 text-sm text-gray-500 sm:py-3">
                  <span class="font-medium text-gray-900">First ever</span> <span class="font-medium text-cyan-500">chia faucet</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="sm:mx-auto sm:max-w-3xl sm:px-6">
          <div class="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <div class="hidden sm:block">
              <div class="absolute inset-y-0 left-1/2 w-screen bg-gray-50 rounded-l-3xl lg:left-80 lg:right-0 lg:w-full" />
              <svg class="absolute top-8 right-1/2 -mr-3 lg:m-0 lg:left-0" width="404" height="392" fill="none" viewBox="0 0 404 392">
                <defs>
                  <pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width="404" height="392" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />
              </svg>
            </div>
            <div class="relative pl-4 -mr-40 sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-12">
              <img class="w-full rounded-md shadow-xl ring-1 ring-black ring-opacity-5 lg:h-full lg:w-auto lg:max-w-none" src="/img/demo_spinner.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      <!-- Testimonial/stats section -->
      <div class="relative mt-20">
        <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
          <div class="relative sm:py-16 lg:py-0">
            <div aria-hidden="true" class="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
              <div class="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72" />
              <svg class="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12" width="404" height="392" fill="none" viewBox="0 0 404 392">
                <defs>
                  <pattern id="02f20b47-fd69-4224-a62a-4c9de5c763f7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width="404" height="392" fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
              </svg>
            </div>
            <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
              <!-- Testimonial card-->
              <div class="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden">
                <img class="absolute inset-0 h-full w-full object-cover" src="/img/faucet-old-5.png" alt="" />
                <div class="absolute inset-0 bg-cyan-200 mix-blend-multiply" />
                <div class="absolute inset-0 bg-gradient-to-t from-cyan-800 via-cyan-350 opacity-90" />
                <div class="relative px-8">
                  <blockquote class="mt-8">
                    <div class="relative text-lg font-medium text-white md:flex-grow">
                      <svg class="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-cyan-400" fill="currentColor" viewBox="0 0 32 32" aria-hidden="true">
                        <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                      </svg>
                      <p class="relative">Awesome, will give this a spin.</p>
                    </div>

                    <footer class="mt-4">
                      <p class="text-base font-semibold text-cyan-200">CIR, chiaforum.com</p>
                    </footer>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>

          <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
            <!-- Content area -->
            <div class="pt-12 sm:pt-16 lg:pt-20">
              <h2 class="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl">On a mission to make you rich</h2>
              <div class="mt-6 text-gray-500 space-y-6">
                <p class="text-lg">
                  Our project started as a testnet faucet for the <b>Chia</b> blockchain.
                  We quickly understood that in order to build a stable and profitable system, we needed to offer more than just free coins.
                </p>
                <p class="text-lg">
                  That's why we've expanded our platform to include exciting crypto games like <b>Dice</b> and <b>Spinner (Roulette)</b> available on multiple blockchains (<b>Litecoin, Dogecoin, Chia</b>) and we have more games like <b>Slots</b> and <b>Crash</b> coming soon.
                </p>
                <p class="text-lg">
                  Plus, we've created an <span class="text-cyan-500">innovative referral system</span> that allows you to earn funds from your referees' activity and a unique stock system that will be available in the future.
                </p>
                <p class="text-lg">
                  Our main goal is to help you grow your crypto wealth, so come <b>join us</b> and <span class="text-cyan-500">let's win together!</span>
                </p>
              </div>
            </div>

            <!-- Stats section -->
            <div class="mt-10">
              <dl class="grid grid-cols-2 gap-x-4 gap-y-8">
                <div v-for="stat in stats" :key="stat.label" class="border-t-2 border-gray-100 pt-6">
                  <dt class="text-base font-medium text-gray-500">{{ stat.label }}</dt>
                  <dd class="text-3xl font-extrabold tracking-tight text-gray-900">{{ stat.value }}</dd>
                </div>
              </dl>
              <div class="mt-10">
                <a href="#roadmap" class="text-base font-medium text-cyan-500"> Learn more about how we're going to change the faucet industry&nbsp&darr; </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Feature section with grid -->
      <div class="relative bg-white py-16 sm:py-24 lg:py-32 lg:mt-48">
        <div class="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
          <h2 id="roadmap" class="text-base font-semibold tracking-wider text-cyan-600 uppercase">Our roadmap</h2>
          <p class="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">What do we want to achieve?</p>
          <p class="mt-5 max-w-prose mx-auto text-xl text-gray-500">
            We work hard to deliver following features for you.
            We have very ambitious plans. Some of them are <span class="bg-gray-200">already brought to life</span>.
            Let's create something great together
          </p>
          <div class="mt-12">
            <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              <div v-for="feature in features" :key="feature.name" class="pt-6">
                <div class="flow-root rounded-lg px-6 pb-8" :class="feature.bodyBackground">
                  <div class="-mt-6">
                    <div>
                        <span class="inline-flex items-center justify-center p-3 bg-gradient-to-r from-teal-500 to-cyan-600 rounded-md shadow-lg">
                          <component :is="feature.icon" class="h-6 w-6 text-white" aria-hidden="true" />
                        </span>
                    </div>
                    <h3 class="mt-8 text-lg font-medium text-gray-900 tracking-tight">{{ feature.title }}</h3>
                    <p class="mt-5 text-base text-gray-500">
                      {{ feature.body }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- CTA Section -->
      <div class="relative bg-cyan-700 sm:mt-28">
        <div class="relative h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
          <img class="w-full h-full object-cover" src="https://images.unsplash.com/photo-1635840419813-b519dc7b187f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2069&q=80" alt="" />
          <div aria-hidden="true" class="absolute inset-0 bg-gradient-to-r from-teal-500 to-cyan-600 mix-blend-multiply" />
        </div>
        <div class="relative mx-auto max-w-md px-4 py-12 sm:max-w-7xl sm:px-6 sm:py-20 md:py-28 lg:px-8 lg:py-32">
          <div class="md:ml-auto md:w-1/2 md:pl-10">
            <h2 class="text-base font-semibold uppercase tracking-wider text-cyan-100">Earn with us</h2>
            <p class="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">Are you ready to start?</p>
            <p class="mt-3 text-lg text-cyan-100">Just a couple of steps left.<br/> Play to earn. Manage to earn. Invest to earn</p>
            <div class="mt-8">
              <div class="inline-flex rounded-md shadow">
                <div class="mt-4 sm:mt-0">
                  <RouterLink :to="{ name: 'register' }" type="submit" class="btn-default sm:px-10 px-5 py-3 block w-full text-center">Sign up</RouterLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <!-- Footer section -->
    <footer class="mt-24 bg-gray-50 sm:mt-12">
      <div class="mx-auto max-w-md py-12 px-4 overflow-hidden sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        <nav class="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
          <div v-for="item in footerNavigation.main" :key="item.name" class="px-5 py-2">
            <a :href="item.href" class="text-base text-gray-400 hover:text-gray-300">
              {{ item.name }}
            </a>
          </div>
        </nav>
        <div class="mt-8 flex justify-center space-x-6">
          <a v-for="item in footerNavigation.social" :key="item.name" :href="item.href" class="text-gray-400 hover:text-gray-300">
            <span class="sr-only">{{ item.name }}</span>
            <img :src="item.icon" class="h-6 w-6" aria-hidden="true" />
          </a>
        </div>
        <p class="mt-8 text-center text-base text-gray-400">&copy; {{ new Date().getFullYear() }} Playcet. All rights reserved.</p>
      </div>
    </footer>
  </div>
</template>

<script setup>
import {
  ChevronRightIcon,
  CubeIcon,
  GlobeAltIcon,
  HandRaisedIcon,
  HandThumbUpIcon,
  LinkIcon,
  PhotoIcon,
  ShieldCheckIcon,
  Square2StackIcon,
  UsersIcon,
} from '@heroicons/vue/20/solid';

const features = [
  {
    title: 'Website redesign',
    href: '#',
    icon: HandThumbUpIcon,
    iconForeground: 'text-teal-700',
    iconBackground: 'bg-teal-50',
    bodyBackground: 'bg-gray-200',
    body: 'Modern flexible and responsive UI/UX',
  },
  {
    title: 'Fully functional wallet',
    href: '#',
    icon: ShieldCheckIcon,
    iconForeground: 'text-purple-700',
    iconBackground: 'bg-purple-50',
    bodyBackground: 'bg-gray-200',
    body: 'Fully functional wallet. Withdraw and deposit your funds',
  },
  {
    title: 'Multiple blockchains',
    href: '#',
    icon: Square2StackIcon,
    iconForeground: 'text-indigo-700',
    iconBackground: 'bg-indigo-50',
    bodyBackground: 'bg-gray-200',
    body: 'Litecoin (LTC), Dogecoin (DOGE) and Chia (XCH) and many more soon',
  },
  {
    title: 'Innovative referral system',
    href: '#',
    icon: LinkIcon,
    iconForeground: 'text-rose-700',
    iconBackground: 'bg-rose-50',
    bodyBackground: 'bg-gray-200',
    body: 'Each time your referee loses, you receive profit. Start referring your friends and earn money effortlessly.',
  },
  {
    title: 'Multiple games',
    href: '#',
    icon: CubeIcon,
    iconForeground: 'text-sky-700',
    iconBackground: 'bg-sky-50',
    bodyBackground: 'bg-gray-50',
    body: 'Best crypto games for you: Dice, Spinner (Roulette), Slots, Crash',
  },
  {
    title: 'PVP games',
    href: '#',
    icon: UsersIcon,
    iconForeground: 'text-yellow-700',
    iconBackground: 'bg-yellow-50',
    bodyBackground: 'bg-gray-50',
    body: 'Even more PVP games so that you can challenge other players',
  },
  {
    title: 'Community platform',
    href: '#',
    icon: GlobeAltIcon,
    iconForeground: 'text-indigo-700',
    iconBackground: 'bg-indigo-50',
    bodyBackground: 'bg-gray-50',
    body: 'Create your game and earn every time someone plays',
  },
  {
    title: 'Decentralised management',
    href: '#',
    icon: HandRaisedIcon,
    iconForeground: 'text-indigo-700',
    iconBackground: 'bg-indigo-50',
    bodyBackground: 'bg-gray-50',
    body: 'Unique stock system that allows anyone to become a real investor and take part in management as well as share our profit',
  },
  {
    title: 'Nft and metaverse',
    href: '#',
    icon: PhotoIcon,
    iconForeground: 'text-indigo-700',
    iconBackground: 'bg-indigo-50',
    bodyBackground: 'bg-gray-50',
    body: 'Leverage metaverses as one more way to earn some crypto',
  },
];

const stats = [
  { label: 'Founded', value: '2021' },
  // { label: 'Employees', value: '4' },
  { label: 'Unique monthly users', value: '~15000' },
  // { label: 'Raised', value: '$25M' },
]
const logos = [
  { name: 'Transistor', url: 'https://tailwindui.com/img/logos/transistor-logo-gray-400.svg' },
  { name: 'Mirage', url: 'https://tailwindui.com/img/logos/mirage-logo-gray-400.svg' },
  { name: 'Tuple', url: 'https://tailwindui.com/img/logos/tuple-logo-gray-400.svg' },
  { name: 'Laravel', url: 'https://tailwindui.com/img/logos/laravel-logo-gray-400.svg' },
  { name: 'StaticKit', url: 'https://tailwindui.com/img/logos/statickit-logo-gray-400.svg' },
  { name: 'Workcation', url: 'https://tailwindui.com/img/logos/workcation-logo-gray-400.svg' },
]
const footerNavigation = {
  main: [
    { name: 'Terms', href: '/terms-of-service' },
    { name: 'Privacy', href: '/privacy-policy' },
  ],
  social: [
    {
      name: 'Chia Forum',
      href: 'https://chiaforum.com/u/chia-faucet/summary',
      icon: '/img/chia-forum.svg'
    },
    {
      name: 'Bitcointalk',
      href: 'https://bitcointalk.org/index.php?action=profile;u=3367135',
      icon: '/img/bitcointalk.svg'
    },
  ],
}
</script>
