<template>
  <div class="hidden sm:block">
    <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex flex-col mt-2">
        <div class="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">

          <table class="min-w-full divide-y divide-gray-200">
            <thead>
            <tr>
              <th class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Type
              </th>
              <th class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Address
              </th>
              <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Amount
              </th>
              <th class="hidden px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:block">
                Status
              </th>
              <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Date
              </th>
            </tr>
            </thead>

            <tbody v-if="transactionPaginator.meta.total === 0">
              <tr>
                <td class="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  No records found
                </td>
              </tr>
            </tbody>

            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="transaction in transactionPaginator.data" :key="transaction.id" class="bg-white">
                <td class="hidden px-6 py-4 whitespace-nowrap text-sm text-gray-500 md:block">
                  <span class="inline-flex items-center py-0.5 rounded-full text-xs font-medium capitalize">
                    {{ transaction.type }}
                  </span>
                </td>
                <td class="max-w-0 px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  <div class="flex">
                    <a @click.prevent="copyAddress(transaction.to)" class="group inline-flex space-x-2 text-sm text-gray-500 hover:text-gray-900">
                      {{ formatAddress(transaction.to) }} <ClipboardDocumentIcon class="inline h-5 w-5 ml-1" aria-hidden="true" />
                    </a>
                  </div>
                </td>
                <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                  <span class="text-gray-900 font-medium">{{ formatCrypto(transaction.amount) }} </span>
                  {{ transaction.account.currency.symbol }}
                </td>
                <td class="hidden px-6 py-4 whitespace-nowrap text-sm text-gray-500 md:block">
                  <span :class="[statusStyles[transaction.status], 'inline-flex items-center py-0.5 rounded-full text-xs font-medium capitalize']">
                    {{ transaction.status.toLowerCase() }}
                  </span>
                </td>
                <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                  <time :datetime="transaction.created_at">{{ formatDt(transaction.created_at) }}</time>
                </td>
              </tr>
            </tbody>
          </table>

          <DesktopPagination :paginator="transactionPaginator" @paginate-to="$emit('paginate-to', $event)"></DesktopPagination>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    ArrowLeftOnRectangleIcon,
    ArrowRightOnRectangleIcon,
  } from '@heroicons/vue/24/outline'
  import CopiesAddress from '@/views/Address/CopiesAddress';
  import FormatsDt from '@/mixins/FormatsDt';
  import DesktopPagination from '@/components/pagination/DesktopPagination';
  import FormatsAddress from '@/mixins/FormatsAddress';
  import { ClipboardDocumentIcon } from '@heroicons/vue/20/solid';
  import HasStatusStyles from '@/mixins/HasStatusStyles';
  import FormatsCrypto from '@/mixins/FormatsCrypto'


  export default {
    components: {
      ArrowLeftOnRectangleIcon,
      ArrowRightOnRectangleIcon,
      DesktopPagination,
      ClipboardDocumentIcon,
    },

    mixins: [
      CopiesAddress,
      FormatsDt,
      FormatsAddress,
      HasStatusStyles,
      FormatsCrypto,
    ],

    props: {
      transactionPaginator: Object,
    },
  }
</script>

<style scoped>

</style>
