<template>
  <div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="mx-auto w-full max-w-sm lg:w-96 px-4">
      <img class="mx-auto h-12 w-auto" src="/img/logo-cyan-700.svg" alt="playcet" />
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in</h2>

      <div class="mt-8">
        <div class="m t-6">
          <form @submit.prevent="logIn" class="space-y-6">
            <div>
              <label for="email" class="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <div class="mt-1">
                <input v-model="form.email"
                       id="email" name="email" type="email" autocomplete="email" required=""
                       class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm"/>
              </div>
              <HasError :form="form" field="email"></HasError>
            </div>

            <div class="space-y-1">
              <label for="password" class="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div class="mt-1 relative">
                <input v-model="form.password"
                       id="password" name="password" :type="isVisiblePassword ? 'text' : 'password'" autocomplete="current-password" required=""
                       class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm"
                />
                <div class="absolute inset-y-0 right-0 flex items-center pr-3">
                  <EyeIcon @click="isVisiblePassword = true" v-if="!isVisiblePassword" class="h-5 w-5 text-gray-500 cursor-pointer" aria-hidden="true"/>
                  <EyeSlashIcon @click="isVisiblePassword = false" v-if="isVisiblePassword" class="h-5 w-5 text-gray-500 cursor-pointer" aria-hidden="true"/>
                </div>
              </div>
              <HasError :form="form" field="password"></HasError>
            </div>

            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <input v-model="remember" id="remember-me" name="remember-me" type="checkbox"
                       class="h-4 w-4 text-cyan-600 focus:ring-cyan-500 border-gray-300 rounded"/>
                <label for="remember-me" class="ml-2 block text-sm text-gray-900">
                  Remember me
                </label>
              </div>

              <div class="text-sm">
                <RouterLink :to="{ name: 'forgot-password' }" class="font-medium text-cyan-600 hover:text-cyan-500">
                  Forgot your password?
                </RouterLink>
              </div>
            </div>

            <div>
              <button type="submit"
                      :class="{ 'opacity-25': form.busy }"
                      :disabled="form.busy"
                      class="w-full justify-center btn-filled"
              >
                Sign in
              </button>
            </div>
          </form>

          <div>
            <div class="mt-6 relative">
              <div class="absolute inset-0 flex items-center" aria-hidden="true">
                <div class="w-full border-t border-gray-300"/>
              </div>
              <div class="relative flex justify-center text-sm">
                <span class="px-2 bg-gray-50 text-gray-500">
                  Or
                </span>
              </div>
            </div>
          </div>

          <div class="mt-6 relative">
            <RouterLink :to="{ name: 'register' }" type="button"
                         class="w-full justify-center btn-default"
            >
              Sign up
            </RouterLink>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ChevronRightIcon, StarIcon, EyeIcon, EyeSlashIcon } from '@heroicons/vue/20/solid'
  import AuthApi from '@/api/AuthApi';
  import { Form } from 'vform';
  import { HasError } from 'vform/src/components/tailwind';

  export default {
    components: {
      ChevronRightIcon,
      StarIcon,
      HasError,
      EyeSlashIcon,
      EyeIcon,
    },

    data() {
      return {
        remember: false,
        isVisiblePassword: false,
        form: new Form({
          email: '',
          password: '',
        })
      }
    },

    methods: {
      async logIn() {
        try {
          const response = await AuthApi.logIn(this.form);
          const token = response.data.token;
          const remember = this.remember;

          await this.$store.dispatch('auth/logIn', { token, remember });
          this.$router.push(this.$route.query.redirect || { name: 'home' });
        } catch (e) {
          console.log(e);
        }

        this.form.update({
          password: '',
        });
      },
    }
  }
</script>
