<template>
  <div class="hidden sm:block">
    <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex flex-col mt-2">
        <div class="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead>
            <tr>
              <th class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Address
              </th>
              <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Created
              </th>
            </tr>
            </thead>

            <tbody v-if="addressPaginator.meta.total === 0">
              <tr>
                <td class="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  No records found
                </td>
              </tr>
            </tbody>

            <tbody v-else class="bg-white divide-y divide-gray-200">
            <tr v-for="address in addressPaginator.data" :key="address.id" class="bg-white">
              <td class="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                <div class="flex">
                  <a @click.prevent="copyAddress(address.value)" class="group inline-flex space-x-2 truncate text-sm cursor-pointer">
                    <ClipboardDocumentIcon class="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                       aria-hidden="true"/>
                    <p class="text-gray-500 truncate group-hover:text-gray-900">
                      {{ address.value }}
                    </p>
                  </a>
                </div>
              </td>
              <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
               <span
                 :class="[statusStyles[address.type], 'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize']">
                    {{ address.type }}
               </span>
              </td>
              <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                <time :datetime="address.created_at">{{ formatDt(address.created_at) }}</time>
              </td>
            </tr>
            </tbody>

          </table>

          <DesktopPagination :paginator="addressPaginator" @paginate-to="$emit('paginate-to', $event)"></DesktopPagination>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    ClipboardDocumentIcon,
  } from '@heroicons/vue/20/solid'
  import CopiesAddress from '@/views/Address/CopiesAddress';
  import FormatsDt from '@/mixins/FormatsDt';
  import DesktopPagination from '@/components/pagination/DesktopPagination';


  export default {
    components: {
      ClipboardDocumentIcon,
      DesktopPagination,
    },

    mixins: [
      CopiesAddress,
      FormatsDt,
    ],

    props: {
      addressPaginator: Object,
      statusStyles: Object,
    },
  }
</script>

<style scoped>

</style>
