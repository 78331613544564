<template>
  <AuthLayout>
    <VWait for="data">

      <template v-slot:waiting>
        <RadarSpinner/>
      </template>

      <main class="flex-1 relative pb-8 z-0 overflow-y-auto">

        <PageHeader>
          <template #name>History</template>
          <template #control>
            <RouterLink
                :to="{ name: 'dice' }"
                class="btn-default"
            >
              Dice
            </RouterLink>
          </template>
        </PageHeader>

        <div class="mt-8">
          <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="flex flex-col mt-2">
              <div class="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">

                <table class="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Nonce
                      </th>
                      <th class="hidden px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider lg:table-cell">
                        Server seed (Click to copy)
                      </th>
                      <th class="hidden px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider lg:table-cell">
                        Client seed
                      </th>
                      <th class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden md:table-cell">
                        Bet
                      </th>
                      <th class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Side
                      </th>
                      <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider hidden md:table-cell">
                        Value
                      </th>
                      <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Reward
                      </th>
                    </tr>
                  </thead>

                  <tbody v-if="userDicePaginator.meta.total === 0">
                    <tr>
                      <td class="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        No records found
                      </td>
                    </tr>
                  </tbody>

                  <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-for="dice in userDicePaginator.data" :key="dice.id" class="bg-white">
                      <td class="px-6 py-4 text-left whitespace-nowrap text-sm text-gray-500">
                        <span class="font-medium">{{ dice.nonce }}</span>
                      </td>
                      <td @click="copyText(dice.server_seed)" class="hidden lg:table-cell px-6 py-4 text-left whitespace-nowrap text-sm text-gray-500 hover:bg-gray-50 active:bg-gray-100 cursor-pointer">
                        <span class="truncate w-40 inline-block">{{ dice.server_seed }}</span>
                      </td>
                      <td class="hidden px-6 py-4 text-left whitespace-nowrap text-sm text-gray-500 lg:table-cell">
                        <span class="">{{ dice.client_seed }}</span>
                      </td>
                      <td class="px-6 py-4 text-left whitespace-nowrap text-sm text-gray-500 hidden md:table-cell">
                        <span class="text-gray-900 font-medium">{{ formatCrypto(dice.bet) }}</span> {{ dice.currency_symbol }}
                      </td>
                      <td class="px-6 py-4 text-left whitespace-nowrap text-sm text-gray-500">
                        <span class="text-gray-500">{{ formatDiceSide(dice.side) }}</span>
                      </td>
                      <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500 hidden md:table-cell">
                        <span class="">{{ dice.value }}</span>
                      </td>
                      <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                        <span class="font-medium"
                              :class="[ dice.reward > 0 ? 'text-green-600' : 'text-red-400' ]"
                        >
                          {{ formatCrypto(dice.reward) }}
                        </span> {{ dice.currency_symbol }}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <DesktopPagination :paginator="userDicePaginator" @paginate-to="handlePagination"></DesktopPagination>

              </div>
            </div>
          </div>
        </div>
      </main>
    </VWait>
  </AuthLayout>
</template>

<script>
import AuthLayout from '@/components/layouts/AuthLayout';
import PageHeader from '@/components/PageHeader';
import RadarSpinner from '@/components/RadarSpinner';
import FormatsCrypto from '@/mixins/FormatsCrypto';
import UserDice from '@/resources/UserDice';
import FormatsDt from '@/mixins/FormatsDt';
import {copyText} from 'vue3-clipboard';
import DesktopPagination from '@/components/pagination/DesktopPagination';
import FormatsDiceSide from '@/mixins/FormatsDiceSide';
import CopiesText from '@/mixins/CopiesText';

export default {
  components: {
    RadarSpinner,
    PageHeader,
    AuthLayout,
    DesktopPagination,
  },

  mixins: [FormatsCrypto, FormatsDt, FormatsDiceSide, CopiesText],

  data() {
    return {
      userDicePaginator: null,
    };
  },

  async created() {
    this.$wait.start('data');

    this.userDicePaginator = await UserDice.get();

    this.$wait.end('data');
  },

  methods: {
    async handlePagination(page) {
      this.$wait.start('data');

      this.userDicePaginator = await UserDice.page(page).get();

      this.$wait.end('data');
    },
  },
}
</script>
