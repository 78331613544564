export default {
  namespaced: true,

  state: {
    config: [],
  },

  mutations: {
    setConfig(state, config) {
      state.config = config;
    }
  },

  getters: {
    minWithdrawalBalance(state) {
      return state.config
        .find(config => config.key === 'min_withdrawal_balance')
        ?.value;
    },

    chiaTransactionFee(state) {
      return state.config
        .find(config => config.key === 'chia_transaction_fee')
        ?.value;
    },

    minDiceBet(state) {
      return state.config
        .find(config => config.key === 'min_dice_bet')
        ?.value;
    },

    maxDiceBet(state) {
      return state.config
        .find(config => config.key === 'max_dice_bet')
        ?.value;
    },

    minSpinnerBet(state) {
      return state.config
        .find(config => config.key === 'min_spinner_bet')
        ?.value;
    },

    maxSpinnerBet(state) {
      return state.config
        .find(config => config.key === 'max_spinner_bet')
        ?.value;
    },
  },

  actions: {
    store(context, config) {
      context.commit('setConfig', config);
    },
  },
}
