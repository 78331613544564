<template>
  <div class="radar-spinner">
    <div class="circle">
      <div class="circle-inner-container">
        <div class="circle-inner"></div>
      </div>
    </div>

    <div class="circle">
      <div class="circle-inner-container">
        <div class="circle-inner"></div>
      </div>
    </div>

    <div class="circle">
      <div class="circle-inner-container">
        <div class="circle-inner"></div>
      </div>
    </div>

    <div class="circle">
      <div class="circle-inner-container">
        <div class="circle-inner"></div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {

  }
</script>

<style scoped>
  .radar-spinner, .radar-spinner * {
    box-sizing: border-box;
  }

  .radar-spinner {
    height: calc(100vh - 4rem);
    width: 60px;
    margin: 0 auto;
    position: relative;
  }

  .radar-spinner .circle {
    position: absolute;
    top: 40%;
    height: 60px;
    width: 60px;
    animation: radar-spinner-animation 2s infinite;
  }

  .radar-spinner .circle:nth-child(1) {
    padding: calc(60px * 5 * 2 * 0 / 110);
    animation-delay: 300ms;
  }

  .radar-spinner .circle:nth-child(2) {
    padding: calc(60px * 5 * 2 * 1 / 110);
    animation-delay: 300ms;
  }

  .radar-spinner .circle:nth-child(3) {
    padding: calc(60px * 5 * 2 * 2 / 110);
    animation-delay: 300ms;
  }

  .radar-spinner .circle:nth-child(4) {
    padding: calc(60px * 5 * 2 * 3 / 110);
    animation-delay: 0ms;
  }

  .radar-spinner .circle-inner, .radar-spinner .circle-inner-container {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    border: calc(60px * 5 / 110) solid transparent;
  }

  .radar-spinner .circle-inner {
    border-left-color: #0E7490;
    border-right-color: #0E7490;
  }

  @keyframes radar-spinner-animation {
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
</style>
