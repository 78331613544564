<template>
  <AuthLayout>
    <VWait for="data">

      <template v-slot:waiting>
        <RadarSpinner/>
      </template>

      <main class="flex-1 relative pb-8 z-0 overflow-y-auto">

        <PageHeader>
          <template #name>Email Verification</template>
          <template #control>
            <RouterLink
              :to="{ name: 'home' }"
              class="btn-default"
            >
              Home
            </RouterLink>
          </template>
        </PageHeader>

        <div class="mt-8">

          <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <div>
              <div class="shadow sm:rounded-md sm:overflow-hidden">
                <div class="bg-white py-6 px-4 space-y-6 sm:p-6">

                  <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                      Warning
                    </h3>
                    <p class="mt-1 text-sm text-gray-500">
                      Invalid verification link
                    </p>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
      </main>
    </VWait>
  </AuthLayout>
</template>

<script>
  import AuthLayout from '@/components/layouts/AuthLayout';
  import PageHeader from '@/components/PageHeader';
  import BadgeRequired from '@/components/BadgeRequired';
  import { HasError } from 'vform/src/components/tailwind';
  import axios from 'axios';

  export default {
    components: {
      BadgeRequired,
      PageHeader,
      AuthLayout,
      HasError,
    },

    async created() {
      try {
        const id = this.$route.query.id;
        const hash = this.$route.query.hash;
        const expires = this.$route.query.expires;
        const signature = this.$route.query.signature;

        await axios.get(`${process.env.VUE_APP_API_URL}/auth/email/verify/${id}/${hash}?expires=${expires}&signature=${signature}`)

        this.$router.push({ name: 'home' });
      } catch (e) {
        console.log(e);
      }
    },
  }
</script>
