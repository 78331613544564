<template>
  <div class="shadow sm:hidden">

    <ul v-if="transactionPaginator.meta.total === 0" role="list" class="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
      <li>
        <p class="block px-4 py-4 bg-white">
          No records found
        </p>
      </li>
    </ul>

    <ul v-else role="list" class="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
      <li v-for="transaction in transactionPaginator.data" :key="transaction.id">
        <a href="#" class="block px-4 py-4 bg-white hover:bg-gray-50">
          <span class="flex items-center space-x-4">
            <span class="flex-1 flex space-x-2 truncate">
              <ArrowRightOnRectangleIcon v-if="transaction.type === 'WITHDRAW'" class="flex-shrink-0 h-5 w-5 text-gray-500 group-hover:text-gray-900" aria-hidden="true" />
              <ArrowLeftOnRectangleIcon v-else class="flex-shrink-0 h-5 w-5 text-gray-500 group-hover:text-gray-900" aria-hidden="true" />
              <span class="flex flex-col text-gray-500 text-sm truncate">
                <span>{{ transaction.type }}</span>
                <span class="truncate">{{ transaction.to }}</span>
                <span>
                  <span class="text-gray-900 font-medium">{{ formatCrypto(transaction.amount) }}</span>
                  {{ transaction.account.currency.symbol }}
                </span>
                <span>
                  <time :datetime="transaction.created_at">{{ formatDt(transaction.created_at) }}</time>
                  <span
                    :class="[statusStyles[transaction.status], 'ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize']">
                    {{ transaction.status.toLowerCase() }}
                  </span>
                </span>
              </span>
            </span>
            <ChevronRightIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true"/>
          </span>
        </a>
      </li>
    </ul>

    <ResponsivePagination :paginator="transactionPaginator" @paginate-to="$emit('paginate-to', $event)"></ResponsivePagination>

  </div>
</template>

<script>
  import {
    ChevronRightIcon,
  } from '@heroicons/vue/20/solid';
  import {
    ArrowLeftOnRectangleIcon,
    ArrowRightOnRectangleIcon,
  } from '@heroicons/vue/24/outline';
  import CopiesAddress from '@/views/Address/CopiesAddress';
  import FormatsDt from '@/mixins/FormatsDt';
  import ResponsivePagination from '@/components/pagination/ResponsivePagination';
  import HasStatusStyles from '@/mixins/HasStatusStyles';
  import FormatsCrypto from '@/mixins/FormatsCrypto'

  export default {
    components: {
      ArrowLeftOnRectangleIcon,
      ArrowRightOnRectangleIcon,
      ChevronRightIcon,
      ResponsivePagination,
    },

    mixins: [
      CopiesAddress,
      FormatsDt,
      HasStatusStyles,
      FormatsCrypto,
    ],

    props: {
      transactionPaginator: Object,
    },
  }
</script>

<style scoped>

</style>
