import {copyText} from 'vue3-clipboard';

export default {
  methods: {
    async copyText(text) {
      try {
        await copyText(text, undefined, () => {});
        this.$notify({
          'title': 'Successfully copied!',
        })
      } catch (e) {
        this.$notify({
          'type': 'error',
          'title': 'Oops. Something went wrong',
        })
      }
    },
  }
}
