<template>
  <div class="bg-white min-h-screen px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
    <div class="max-w-max mx-auto">
      <main class="sm:flex">
        <p class="text-4xl font-extrabold text-cyan-600 sm:text-5xl">500</p>
        <div class="sm:ml-6">
          <div class="sm:border-l sm:border-gray-200 sm:pl-6">
            <h1 class="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Server Error</h1>
            <p class="mt-1 text-base text-gray-500">Please try again later.</p>
          </div>
          <div class="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
            <RouterLink :to="{ name: 'home' }" class="btn-filled">
              Go back home
            </RouterLink>
            <a :href="contactEmail" class="btn-default">
              Contact support
            </a>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
  export default {
    computed: {
      contactEmail() {
        return `mailto:${process.env.VUE_APP_CONTACT_EMAIL}`;
      }
    }
  }
</script>
