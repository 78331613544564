import AuthService from '@/service/AuthService';

export default async function verified({ to, next, store }) {
  if (AuthService.isNotLoggedIn()) {
    next({name: 'email.verify'});
  }

  if (store.state.auth.profile.email_verified_at !== null) {
    next();
  }

  next({name: 'email.verify'});
}
