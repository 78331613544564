<template>
  <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <h2 class="text-lg leading-6 font-medium text-gray-900">Overview</h2>

<!--      <div class="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">-->
<!--          <StatCard v-for="stat of stats" :stat="stat" />-->
<!--      </div>-->
    </div>
</template>

<script>
import StatCard from '@/views/Home/StatCard';
export default {
  components: {StatCard},
  props: {
    stats: Array,
  }
};
</script>

<style scoped>

</style>
