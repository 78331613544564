<template>

</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      profile: state => state.auth.profile,
    }),
  },

  mounted () {
    this.$echo
      .private(`user.${this.profile.id}.wallet`)
      .listen('.balance.updated', async (payload) => {
        const accountId = payload.message.account_id;
        const balance = payload.message.balance;
        await this.$store.commit('wallet/setBalance', { accountId, balance });
      })
  },
}
</script>

<style scoped>

</style>
