<template>
  <AuthLayout>
    <VWait for="data">

      <template v-slot:waiting>
        <RadarSpinner/>
      </template>

      <main class="flex-1 relative pb-8 z-0 overflow-y-auto">

        <PageHeader>
          <template #name>History</template>
          <template #control>
            <RouterLink
                :to="{ name: 'faucet' }"
                class="btn-default"
            >
              Faucet
            </RouterLink>
          </template>
        </PageHeader>

        <div class="mt-8">
          <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="flex flex-col mt-2">
              <div class="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">

                <table class="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Nonce
                      </th>
                      <th class="hidden px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider lg:table-cell">
                        Server seed (Click to copy)
                      </th>
                      <th class="hidden px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider lg:table-cell">
                        Client seed
                      </th>
                      <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Value
                      </th>
                      <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Reward
                      </th>
                      <th class="hidden sm:table-cell px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Date
                      </th>
                    </tr>
                  </thead>

                  <tbody v-if="userRollPaginator.meta.total === 0">
                    <tr>
                      <td class="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        No records found
                      </td>
                    </tr>
                  </tbody>

                  <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-for="roll in userRollPaginator.data" :key="roll.id" class="bg-white">
                      <td class="px-6 py-4 text-left whitespace-nowrap text-sm text-gray-500">
                        <span class="font-medium">{{ roll.nonce }}</span>
                      </td>
                      <td @click="copyText(roll.server_seed)" class="hidden lg:table-cell px-6 py-4 text-left whitespace-nowrap text-sm text-gray-500 hover:bg-gray-50 active:bg-gray-100 cursor-pointer">
                        <span class="truncate w-40 inline-block">{{ roll.server_seed }}</span>
                      </td>
                      <td class="hidden px-6 py-4 text-left whitespace-nowrap text-sm text-gray-500 lg:table-cell">
                        <span class="">{{ roll.client_seed }}</span>
                      </td>
                      <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                        <span class="">{{ roll.value }}</span>
                      </td>
                      <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                        <span class="text-green-600 font-medium">{{ formatCrypto(roll.reward) }}</span> XCH
                      </td>
                      <td class="hidden sm:table-cell px-6 py-4 text-left whitespace-nowrap text-sm text-gray-500">
                        <time :datetime="roll.created_at">{{ formatDt(roll.created_at) }}</time>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <DesktopPagination :paginator="userRollPaginator" @paginate-to="handlePagination"></DesktopPagination>

              </div>
            </div>
          </div>
        </div>
      </main>
    </VWait>
  </AuthLayout>
</template>

<script>
import AuthLayout from '@/components/layouts/AuthLayout';
import PageHeader from '@/components/PageHeader';
import RadarSpinner from '@/components/RadarSpinner';
import FormatsCrypto from '@/mixins/FormatsCrypto';
import UserRoll from '@/resources/UserRoll';
import FormatsDt from '@/mixins/FormatsDt';
import DesktopPagination from '@/components/pagination/DesktopPagination';
import CopiesText from '@/mixins/CopiesText';

export default {
  components: {
    RadarSpinner,
    PageHeader,
    AuthLayout,
    DesktopPagination,
  },

  mixins: [FormatsCrypto, FormatsDt, CopiesText],

  data() {
    return {
      userRollPaginator: null,
    };
  },

  async created() {
    this.$wait.start('data');

    this.userRollPaginator = await UserRoll.get();

    this.$wait.end('data');
  },

  methods: {
    async handlePagination(page) {
      this.$wait.start('data');

      this.userRollPaginator = await UserRoll.page(page).get();

      this.$wait.end('data');
    },
  },
}
</script>
