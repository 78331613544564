<template>
  <div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="mx-auto w-full max-w-sm lg:w-96 px-4">
      <div class="mt-8">
        <div class="m t-6">
          <form @submit.prevent="resetPassword" class="space-y-6">
            <div>
              <label for="email" class="block text-sm font-medium text-gray-700">
                Email address <BadgeRequired />
              </label>
              <div class="mt-1">
                <input v-model="form.email"
                  id="email" name="email" type="email" autocomplete="email" required=""
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm"/>
              </div>
              <HasError :form="form" field="email"></HasError>
            </div>

            <div class="space-y-1">
              <label for="password" class="block text-sm font-medium text-gray-700">
                Password <BadgeRequired />
              </label>
              <div class="mt-1 relative">
                <input v-model="form.password"
                       id="password" name="password" :type="isVisiblePassword ? 'text' : 'password'"
                       autocomplete="current-password" required=""
                       class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm"
                />
                <div class="absolute inset-y-0 right-0 flex items-center pr-3">
                  <EyeIcon @click="isVisiblePassword = true" v-if="!isVisiblePassword" class="h-5 w-5 text-gray-500 cursor-pointer" aria-hidden="true"/>
                  <EyeSlashIcon @click="isVisiblePassword = false" v-if="isVisiblePassword" class="h-5 w-5 text-gray-500 cursor-pointer" aria-hidden="true"/>
                </div>
              </div>
              <HasError :form="form" field="password"></HasError>
            </div>

            <div class="space-y-1">
              <label for="password" class="block text-sm font-medium text-gray-700">
                Confirm password <BadgeRequired />
              </label>
              <div class="mt-1 relative">
                <input v-model="form.password_confirmation"
                       id="confirm_password" name="confirm_password" :type="isVisiblePasswordConfirmation ? 'text' : 'password'"
                       required=""
                       class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm"
                />
                <div class="absolute inset-y-0 right-0 flex items-center pr-3">
                  <EyeIcon @click="isVisiblePasswordConfirmation = true" v-if="!isVisiblePasswordConfirmation" class="h-5 w-5 text-gray-500 cursor-pointer" aria-hidden="true"/>
                  <EyeSlashIcon @click="isVisiblePasswordConfirmation = false" v-if="isVisiblePasswordConfirmation" class="h-5 w-5 text-gray-500 cursor-pointer" aria-hidden="true"/>
                </div>
              </div>
              <HasError :form="form" field="confirm_password"></HasError>
            </div>

            <div>
              <button type="submit"
                      :class="{ 'opacity-25': form.busy }"
                      :disabled="form.busy"
                      class="w-full justify-center btn-filled">
                Reset password
              </button>
            </div>
          </form>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Form } from 'vform';
  import AuthApi from '@/api/AuthApi';
  import { HasError } from 'vform/src/components/tailwind';
  import BadgeRequired from '@/components/BadgeRequired'
  import { EyeIcon, EyeSlashIcon } from '@heroicons/vue/20/solid'

  export default {
    components: {
      HasError,
      BadgeRequired,
      EyeIcon,
      EyeSlashIcon,
    },

    data() {
      return {
        isVisiblePassword: false,
        isVisiblePasswordConfirmation: false,
        form: new Form({
          token: this.$route.params.token,
          email: this.$route.query.email,
          password: '',
          password_confirmation: '',
        })
      };
    },

    methods: {
      async resetPassword() {
        try {
          await AuthApi.resetPassword(this.form);
          await this.$router.push({ name: 'login' })
        } catch (e) {
          console.log(e);
        }

        this.form.update({
          password: '',
          password_confirmation: '',
        });
      }
    }
  }
</script>
