import { GiftIcon, HeartIcon } from '@heroicons/vue/20/solid';
import PlaycetIcon from '@/components/PlaycetIcon.vue';

export default {
  components: {
    GiftIcon,
    HeartIcon,
    PlaycetIcon,
  },

  methods: {
    formatSpinnerSide(side) {
      if (side === 'PLAYCET') {
        return 'PlaycetIcon';
      }

      if (side === 'GIFT') {
        return 'GiftIcon';
      }

      if (side === 'HEART') {
        return 'HeartIcon';
      }
    },

    formatSpinnerValue(value) {
      if (value === 0) {
        return 'PlaycetIcon';
      }

      if (value % 2 === 0) {
        return 'GiftIcon';
      }

      if (value % 2 !== 0) {
        return 'HeartIcon';
      }
    },
  },
}
