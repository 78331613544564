<template>
  <nav v-if="isVisible" class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
       aria-label="Pagination">
    <div class="hidden sm:block">
      <p class="text-sm text-gray-700">
        Showing
        <span class="font-medium">{{ paginator.meta.from }}</span>
        to
        <span class="font-medium">{{ paginator.meta.to }}</span>
        of
        <span class="font-medium">{{ paginator.meta.total }}</span>
        results
      </p>
    </div>
    <div class="flex-1 flex justify-end">
      <button v-if="paginator.links.prev" @click="prev"
         class="btn-default">
        Previous
      </button>
      <button v-if="paginator.links.next" @click="next"
         class="ml-3 btn-default">
        Next
      </button>
    </div>
  </nav>
</template>

<script>
  export default {
    emits: {
      paginateTo: Number,
    },

    props: {
      paginator: Object,
    },

    computed: {
      isVisible() {
        return this.paginator.meta.total > this.paginator.meta.per_page;
      }
    },

    methods: {
      prev() {
        this.$emit('paginateTo', this.paginator.meta.current_page - 1);
      },

      next() {
        this.$emit('paginateTo', this.paginator.meta.current_page + 1);
      }
    },
  }
</script>

<style scoped>

</style>
