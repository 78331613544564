<template>
  <AuthLayout>
    <VWait for="init">

      <template v-slot:waiting>
        <RadarSpinner/>
      </template>

      <main class="flex-1 relative pb-8 z-0 overflow-y-auto">

        <PageHeader>
          <template #name>My referrals</template>
          <template #control>
            <RouterLink
                :to="{ name: 'home' }"
                class="btn-default"
            >
              <HomeIcon class="h-4 w-4 text-cyan-600" aria-hidden="true"></HomeIcon>
            </RouterLink>
            <RouterLink
                :to="{ name: 'referrals.leaderboard' }"
                class="btn-filled"
            >
              Top
            </RouterLink>
          </template>
        </PageHeader>

        <div class="mt-8">
          <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="flex flex-col mt-2">
              <div class="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">

                <table class="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        User
                      </th>
                      <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Lost Dices
                      </th>
                      <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Total Profit
                      </th>
                      <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider hidden sm:table-cell">
                        Joined at
                      </th>
                    </tr>
                  </thead>

                  <tbody v-if="referralPaginator.meta.total === 0">
                    <tr>
                      <td class="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        No records found
                      </td>
                    </tr>
                  </tbody>

                  <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-for="referral in referralPaginator.data" :key="referral.id" @click="activate(referral)" class="bg-white cursor-pointer">
                      <td class="px-6 py-4 text-left whitespace-nowrap text-sm text-gray-500">
                        <img class="h-10 w-10 rounded-full inline-flex" :src="referral.profile_photo_url" alt=""/>
                        <span class="ml-2 font-medium text-gray-500">{{ referral.name }}</span>
                      </td>
                      <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                        <span class="">{{ referral.lost_dices_count }}</span>
                      </td>
                      <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                        <span class="text-green-600 font-medium">{{ formatCrypto(referral.total_income) }}</span> {{ activeCurrency.currency_symbol }}
                      </td>
                      <td class="hidden sm:table-cell px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                        <time :datetime="referral.created_at">{{ formatDt(referral.created_at) }}</time>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <DesktopPagination :paginator="referralPaginator" @paginate-to="handlePagination"></DesktopPagination>

              </div>
            </div>
          </div>
        </div>
      </main>
    </VWait>
  </AuthLayout>
</template>

<script>
import AuthLayout from '@/components/layouts/AuthLayout.vue';
import PageHeader from '@/components/PageHeader.vue';
import RadarSpinner from '@/components/RadarSpinner.vue';
import FormatsCrypto from '@/mixins/FormatsCrypto';
import Referral from '@/resources/Referral';
import FormatsDt from '@/mixins/FormatsDt';
import DesktopPagination from '@/components/pagination/DesktopPagination.vue';
import CopiesText from '@/mixins/CopiesText';
import { mapGetters } from 'vuex';
import { HomeIcon } from '@heroicons/vue/20/solid';

export default {
  components: {
    RadarSpinner,
    PageHeader,
    AuthLayout,
    DesktopPagination,
    HomeIcon,
  },

  mixins: [FormatsCrypto, FormatsDt, CopiesText],

  data() {
    return {
      referralPaginator: null,
    };
  },

  computed: {
    ...mapGetters('wallet', [
      'activeCurrency',
    ]),
  },

  watch: {
    async activeCurrency(activeCurrency) {
      this.$wait.start('reload');

      this.referralPaginator = await Referral.where('currency_id', activeCurrency.currency_id).get();

      this.$wait.end('reload');
    }
  },

  async created() {
    this.$wait.start('init');

    this.referralPaginator = await Referral.where('currency_id', this.activeCurrency.currency_id).get();

    this.$wait.end('init');
  },

  methods: {
    async handlePagination(page) {
      this.$wait.start('pagination');

      this.referralPaginator = await Referral.where('currency_id', this.activeCurrency.currency_id).page(page).get();

      this.$wait.end('pagination');
    },

    activate(referee) {
      this.$router.push({ name: 'referees.show', params: { refereeId: referee.id } })
    }
  },
}
</script>
