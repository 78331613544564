import axios from 'axios';

const config = {
  broadcaster: 'pusher',
  key: process.env.VUE_APP_PUSHER_KEY,
  wsHost: process.env.VUE_APP_PUSHER_HOST,
  wssHost: process.env.VUE_APP_PUSHER_HOST,
  wsPort: process.env.VUE_APP_PUSHER_PORT,
  wssPort: process.env.VUE_APP_PUSHER_PORT,
  encrypted: process.env.VUE_APP_PUSHER_SSL === 'true',
  disableStats: true,
  forceTLS: process.env.VUE_APP_PUSHER_SSL === 'true',
  enabledTransports: ['ws', 'wss'],
  authorizer: (channel, options) => {
    return {
      authorize: (socketId, callback) => {
        axios.post(`/broadcasting/auth`, {
          socket_id: socketId,
          channel_name: channel.name,
        }).then(response => {
          callback(null, response.data)
        }).catch(error => {
          callback(error)
        })
      },
    }
  },
}

export default config