<template>
  <AuthLayout>
    <VWait for="data">

      <template v-slot:waiting>
        <RadarSpinner/>
      </template>

      <main class="flex-1 relative pb-8 z-0 overflow-y-auto">

        <PageHeader>
          <template #name>Faucet</template>
          <template #control>
            <RouterLink
              :to="{ name: 'home' }"
              class="btn-default"
            >
              <HomeIcon class="h-4 w-4 text-cyan-600" aria-hidden="true"></HomeIcon>
            </RouterLink>
            <RouterLink
                :to="{ name: 'faucet.index' }"
                class="btn-filled"
            >
              History
            </RouterLink>
          </template>
        </PageHeader>

        <div class="mt-8">
          <h2 class="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
            Roll
          </h2>

          <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-2">

            <form @submit.prevent="roll">
              <div class="shadow sm:rounded-md sm:overflow-hidden">
                <div class="bg-white py-6 px-4 space-y-6 sm:p-6">

                  <div class="space-y-6 sm:space-y-5">
                    <div class="space-y-6 sm:space-y-5">

                      <Disclosure as="div" v-slot="{ open }">
                        <dt class="text-lg">
                          <DisclosureButton class="text-left w-full flex justify-between items-start text-gray-400">
                            <span class="font-medium text-gray-900">
                              Provably fair
                            </span>
                            <span class="ml-6 h-7 flex items-center">
                              <ChevronDownIcon :class="[open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform']" aria-hidden="true" />
                            </span>
                          </DisclosureButton>
                        </dt>
                        <DisclosurePanel as="dd" class="mt-2 space-y-6 sm:space-y-5">
                          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label for="server_seed_hash" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Server seed hash (sha256)
                            </label>
                            <div class="mt-1 sm:mt-0 sm:col-span-2">
                              <input type="text" v-model="form.server_seed_hash" disabled="disabled" id="server_seed_hash" class="input-text-disabled" />
                            </div>
                          </div>

                          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label for="client_seed" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Client seed
                            </label>
                            <div class="mt-1 sm:mt-0 sm:col-span-2">
                              <input type="text" v-model="form.client_seed" id="client_seed" class="input-text" />
                            </div>
                            <HasError class="sm:col-start-2" :form="form" field="client_seed"></HasError>
                          </div>

                          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label for="client_seed" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Nonce
                            </label>
                            <div class="mt-1 sm:mt-0 sm:col-span-2">
                              <input type="number" min="1" v-model="form.nonce" id="nonce" disabled="disabled" class="input-text-disabled" />
                            </div>
                          </div>
                        </DisclosurePanel>
                      </Disclosure>

                      <SlotMachine
                          ref="counter"
                          class="text-[5rem] p-5"
                          @turnCompleted="notifyReward"
                      ></SlotMachine>

                      <div v-if="timer" class="text-center text-red-500">
                        <VueCountdown :time="timer" v-slot="{ minutes, seconds }" @end="refreshLastRoll">
                          Time Remaining: {{ minutes }} minutes, {{ seconds }} seconds.
                        </VueCountdown>
                      </div>
                      <div v-show="!timer" class="sm:grid sm:grid-cols-3">
                        <VueHcaptcha
                            ref="captcha"
                            class="sm:col-start-2"
                            :sitekey="captchaSiteKey"
                            @verify="onVerify"
                        />
                        <HasError class="sm:col-start-2" :form="form" field="captcha"></HasError>
                      </div>

                    </div>
                  </div>

                </div>
                <div v-if="!timer" class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <button type="submit"
                          class="btn-filled"
                          :class="{ 'opacity-25': form.busy }"
                          :disabled="form.busy"
                  >
                    Claim
                  </button>
                </div>
              </div>
            </form>


          </div>

          <h2 class="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
            Rewards
          </h2>

          <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="flex flex-col mt-2">
              <div class="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">Range</th>
                      <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">Payout</th>
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-for="reward in rewards" :key="reward.id" class="bg-white">
                      <td class="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        <div class="flex">
                          <p class="text-gray-500 truncate group-hover:text-gray-900">
                            {{ reward.min === reward.max ? reward.min : `${reward.min} - ${reward.max}` }}
                          </p>
                        </div>
                      </td>
                      <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                        <span class="text-gray-900 font-medium">{{ formatCrypto(reward.payout) }} </span> XCH
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>
      </main>
    </VWait>
  </AuthLayout>
</template>

<script>
import AuthLayout from '@/components/layouts/AuthLayout';
import PageHeader from '@/components/PageHeader';
import RadarSpinner from '@/components/RadarSpinner';
import BadgeRequired from '@/components/BadgeRequired';
import Form from 'vform';
import {HasError} from 'vform/src/components/tailwind';
import {Disclosure, DisclosureButton, DisclosurePanel} from '@headlessui/vue';
import { ChevronDownIcon, HomeIcon } from '@heroicons/vue/20/solid';
import SlotMachine from '@/components/SlotMachine.vue';
import moment from 'moment';
import VueCountdown from '@chenfengyuan/vue-countdown';
import RollApi from '@/api/RollApi';
import Roll from '@/resources/Roll';
import FormatsCrypto from '@/mixins/FormatsCrypto';
import VueHcaptcha from '@hcaptcha/vue3-hcaptcha';
import Reward from '@/resources/Reward';
import GensClientSeed from '@/mixins/GensClientSeed';

export default {
  components: {
    RadarSpinner,
    PageHeader,
    AuthLayout,
    BadgeRequired,
    HasError,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronDownIcon,
    SlotMachine,
    VueCountdown,
    VueHcaptcha,
    HomeIcon,
  },

  mixins: [FormatsCrypto, GensClientSeed],

  data() {
    return {
      form: new Form({
        server_seed_hash: '',
        client_seed: '',
        nonce: '',
        captcha: '',
      }),
      lastRoll: null,
      currentRoll: null,
      captchaSiteKey: process.env.VUE_APP_CAPTCHA_SITE_KEY,
      rewards: [],
    };
  },

  async created() {
    this.$wait.start('data');

    await this.$store.dispatch('wallet/setActiveCurrencyId', 1);

    try {
      this.rewards = await Reward.all();
      this.currentRoll = await Roll.custom('rolls/current').first();
      this.resetForm();

      this.lastRoll = await Roll.custom('rolls/last').first();
    } catch (e) {
      console.log(e);
    }

    this.$wait.end('data');
  },

  computed: {
    timer() {
      const lastRoll = this.lastRoll;

      if (lastRoll === null) {
        return null;
      }

      const lastRollTime = lastRoll.updated_at;

      const nextRollTime = moment(lastRollTime).add(1, 'hour');
      if (nextRollTime.isSameOrBefore(moment())) {
        return null;
      }

      return nextRollTime.diff(moment(), 'milliseconds');
    },
  },

  methods: {
    onVerify(token) {
      this.form.captcha = token;
    },

    resetForm() {
      this.form.server_seed_hash = this.currentRoll.server_seed_hash;
      this.form.client_seed = this.genClientSeed();
      this.form.nonce = this.currentRoll.nonce;
    },

    notifyReward() {
      if (this.lastRoll === null) {
        return;
      }

      this.$notify({
        title: 'Success!',
        text: `Your reward ${this.formatCrypto(this.lastRoll.reward)}`
      });
    },

    async refreshLastRoll() {
      this.lastRoll = await Roll.custom('rolls/last').first();
    },

    async roll() {
      try {
        const { data } = await RollApi.store(this.form);
        this.currentRoll = data.current;
        this.lastRoll = data.last
        this.$refs.counter.turnTo(this.lastRoll.value)
        this.resetForm();
      } catch (e) {
        console.log(e);
      } finally {
        this.$refs.captcha.reset();
      }
    },
  },
}
</script>
