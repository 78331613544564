<template>
  <div class="relative h-screen flex overflow-hidden bg-gray-100">
    <SidebarMenu @sidebarToggled="onSidebarToggle" :sidebarOpen="sidebarOpen"></SidebarMenu>

    <div class="flex-1 overflow-auto focus:outline-none">
      <TopBar @sidebarToggled="onSidebarToggle" @chatToggled="onChatToggle"></TopBar>
      <slot></slot>
    </div>

    <SidebarChat @chatToggled="onChatToggle" :chatOpen="chatOpen"></SidebarChat>

<!--    Auth global service components -->
    <BalanceWatcher/>
  </div>
</template>

<script>
import SidebarMenu from '@/components/SidebarMenu';
import TopBar from '@/components/TopBar';
import RadarSpinner from '@/components/RadarSpinner';
import SidebarChat from '@/components/SidebarChat';
import BalanceWatcher from '@/components/BalanceWatcher';

export default {
    components: {
      SidebarChat,
      SidebarMenu,
      TopBar,
      RadarSpinner,
      BalanceWatcher,
    },

    data() {
      return {
        sidebarOpen: false,
        chatOpen: false,
      };
    },

    methods: {
      onSidebarToggle(sidebarOpen) {
        this.sidebarOpen = sidebarOpen;
      },

      onChatToggle(chatOpen) {
        this.chatOpen = chatOpen;
      }
    },
  }
</script>

<style scoped>

</style>
