import {copyText} from 'vue3-clipboard';
import Big from 'big.js'

export default {
  methods: {
    betMin() {
      this.form.bet = this.minBet;
    },

    betTwice() {
      const twice = (new Big(this.form.bet)).mul(2);

      const max = this.maxBet;

      this.form.bet = twice.lt(max)
        ? twice.toString()
        : max
    },

    betHalf() {
      const half = (new Big(this.form.bet)).div(2);

      const min = this.minBet;

      this.form.bet = half.gt(min)
        ? half.toString()
        : min
    },

    betMax() {
      this.form.bet = this.maxBet;
    },
  }
}
