const ProfileApi = {
  update(form) {
    form._method = 'PATCH';
    return form.post('auth/profile');
  },

  updatePassword(form) {
    return form.put('auth/user/password');
  }
}

export default ProfileApi;
