import router from '@/router';
import AccountService from '@/service/AccountService';
import Big from 'big.js';

export default {
  namespaced: true,

  state: {
    activeCurrencyId: localStorage.getItem('activeCurrencyId') ?? null,
    currencies: [],
  },

  mutations: {
    setCurrencies(state, currencies) {
      state.currencies = currencies;
    },

    setBalance(state, { accountId, balance }) {
      const index = state.currencies.findIndex(currency => currency.account_id === accountId);
      state.currencies[index].balance = balance;
    },

    setActiveCurrencyId(state, activeCurrencyId) {
      localStorage.setItem('activeCurrencyId', parseInt(activeCurrencyId));
      state.activeCurrencyId = parseInt(activeCurrencyId);
    }
  },

  getters: {
    activeCurrency(state) {
      if (state.activeCurrencyId === null) {
        return state.currencies[0];
      }

      return state.currencies
        .find(currency => currency.currency_id === parseInt(state.activeCurrencyId));
    }
  },

  actions: {
    async setActiveCurrencyId(context, activeCurrencyId) {
      if (router.currentRoute.value.name === 'faucet') {
        activeCurrencyId = 1;
      }

      context.commit('setActiveCurrencyId', activeCurrencyId);
      const activeCurrency = context.getters.activeCurrency;

      if (activeCurrency.account_id === null) {
        const account = await AccountService.createAccount(activeCurrency.currency_id);
        context.commit('setCurrencies', context.state.currencies.map(currency => {
          if (currency.currency_id === activeCurrency.currency_id) {
            currency.account_id = account.id;
            currency.balance = account.balance;
            currency.address = account.address;
          }

          return currency;
        }));
      }

      if (
        new Big(activeCurrency.balance).eq(0) &&
        ['dice', 'spinner'].includes(router.currentRoute.value.name)
      ) {
        await router.push({
          name: 'deposit',
          query: { currencyId: activeCurrency.currency_id }
        });
      }
    }
  }
}
