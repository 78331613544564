<template>
  <div class="shadow sm:hidden">

    <ul v-if="paginator.meta.total === 0" role="list" class="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
      <li>
        <p class="block px-4 py-4 bg-white">
          No records found
        </p>
      </li>
    </ul>

    <TransitionGroup tag="ul" name="list" v-else role="list" class="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
      <li v-for="spinner in paginator.data" :key="spinner.id">
        <div class="block px-4 py-4 bg-white hover:bg-gray-50">
          <span class="flex items-center space-x-4">
            <span class="flex-1 flex space-x-2 truncate">
              <span class="flex flex-col max-w-[4rem]">
                <img class="h-10 w-10 rounded-full inline-flex" :src="spinner.user.profile_photo_url" alt=""/>
                <span class="font-medium text-gray-500 truncate">{{ spinner.user.name }}</span>
              </span>
              <span class="flex flex-col text-gray-500 text-sm truncate">
                <span>
                  <span>
                    <span class="text-gray-900 font-medium">
                      <Component :is="formatSpinnerSide(spinner.side)" class="h-6 w-6 inline-flex text-cyan-600"/>
                    </span><Component :is="formatSpinnerValue(spinner.value)" class="h-6 w-6 inline-flex text-cyan-600"/>
                  </span>
                  <span class="ml-2 font-medium" :class="[ spinner.reward > 0 ? 'text-green-600' : 'text-red-400' ]">
                    {{ formatCrypto(spinner.reward) }}
                  </span> {{ spinner.currency_symbol }}
                </span>
                <span>
                  <time :datetime="spinner.created_at">{{ formatDt(spinner.updated_at) }}</time>
                </span>
              </span>
            </span>
          </span>
        </div>
      </li>
    </TransitionGroup>

    <ResponsivePagination :paginator="paginator" @paginate-to="$emit('paginate-to', $event)"></ResponsivePagination>

  </div>
</template>

<script>
import { ChevronRightIcon } from '@heroicons/vue/20/solid'
import { BanknotesIcon, Square2StackIcon } from '@heroicons/vue/24/outline'
import FormatsDt from '@/mixins/FormatsDt'
import ResponsivePagination from '@/components/pagination/ResponsivePagination'
import FormatsCrypto from '@/mixins/FormatsCrypto'
import FormatsSpinner from '@/mixins/FormatsSpinner';

export default {
    components: {
      BanknotesIcon,
      Square2StackIcon,
      ChevronRightIcon,
      ResponsivePagination,
    },

    mixins: [
      FormatsDt,
      FormatsCrypto,
      FormatsSpinner,
    ],

    props: {
      paginator: Object,
    },
  }
</script>

<style scoped>
  .list-enter-active,
  .list-leave-active {
    transition: all 0.5s ease;
  }
  .list-enter-from,
  .list-leave-to {
    opacity: 0;
    transform: translateX(30px);
  }
</style>
