<template>
  <Listbox as="div" :defaultValue="activeCurrency" @update:modelValue="handleSelect">
    <ListboxLabel class="sr-only"> Change published status </ListboxLabel>
    <div class="relative">
      <div class="inline-flex divide-x divide-cyan-700 rounded-md shadow-sm">
        <div class="inline-flex divide-x divide-cyan-700 rounded-md shadow-sm">
          <RouterLink
              :to="{ name: 'deposit' }"
              class="inline-flex items-center rounded-l-md border border-transparent bg-cyan-600 py-2 px-2 text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-600 focus:ring-offset-2 focus:ring-offset-gray-50"
          >
            <span class="text-xs font-medium">{{ formatCrypto(activeCurrency.balance) }}</span>
          </RouterLink>
          <ListboxButton class="inline-flex items-center rounded-l-none rounded-r-md bg-cyan-600 p-2 text-sm font-medium text-white hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-600 focus:ring-offset-2 focus:ring-offset-gray-50">
            <span class="sr-only">Change published status</span>
            <img class="flex-shrink-0 h-5 w-5"
                 :src="require(`@/assets/icons/${activeCurrency.currency_symbol}.png`)"
            >
            <ChevronDownIcon class="h-5 w-5 text-white" aria-hidden="true" />
          </ListboxButton>
        </div>
      </div>

      <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <ListboxOptions class="absolute left-0 z-10 mt-2 w-64 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <ListboxOption as="template" v-for="currency in currencies" :key="currency.currency_id" :value="currency" v-slot="{ active, activeCurrency }">
            <li :class="[active ? 'text-white bg-cyan-600' : 'text-gray-900', 'cursor-pointer select-none p-4 text-sm']">
              <div class="flex flex-col">
                <div class="flex justify-between">
                  <p :class="activeCurrency ? 'font-semibold' : 'font-normal'">{{ formatCrypto(currency.balance ?? 0) }}</p>
                  <span v-if="activeCurrency" :class="active ? 'text-white' : 'text-cyan-600'">
                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                  </span>
                </div>
                <p :class="[active ? 'text-cyan-200' : 'text-gray-500', 'mt-2']">{{ currency.currency_name }} ({{ currency.currency_symbol }})</p>
              </div>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script>
import FormatsCrypto from '@/mixins/FormatsCrypto'
import { mapGetters, mapState } from 'vuex'
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, ChevronDownIcon } from '@heroicons/vue/20/solid'

export default {
  name: 'CurrencySwitcher',

  components: {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    ChevronDownIcon,
  },

  mixins: [FormatsCrypto],

  computed: {
    ...mapState({
      currencies: state => state.wallet.currencies,
    }),
    ...mapGetters('wallet', [
        'activeCurrency',
    ]),
  },

  methods: {
    async handleSelect(currency) {
      await this.$store.dispatch('wallet/setActiveCurrencyId', currency.currency_id);
    }
  }
}
</script>

<style scoped>

</style>