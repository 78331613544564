<template>
  <input ref='inputRef' type='text' class="input-text" />
</template>

<script>
import { useCurrencyInput } from 'vue-currency-input'
import { watch } from 'vue';

export default {
  name: 'CurrencyInput',

  props: {
    modelValue: [String, Number],
    options: Object,
  },

  setup(props) {
    const { inputRef, setValue, setOptions } = useCurrencyInput(props.options);

    watch(
        () => props.modelValue,
        (value) => {
          setValue(value)
        }
    )

    watch(
        () => props.options,
        (options) => {
          setOptions(options)
        }
    )

    return { inputRef }
  }
}
</script>

<style scoped>

</style>
