<template>
  <AuthLayout>
    <VWait for="data">

      <template v-slot:waiting>
        <RadarSpinner/>
      </template>
      <main class="flex-1 relative pb-8 z-0 overflow-y-auto">

        <PageHeader>
          <template #name>News</template>
          <template #control>
            <RouterLink
              :to="{ name: 'home' }"
              class="btn-default"
            >
              Home
            </RouterLink>
          </template>
        </PageHeader>

        <div class="mt-8">
          <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">

            <div class="shadow sm:rounded-md sm:overflow-hidden">
              <div class="bg-white py-6 px-4 space-y-6 sm:p-6">

                  <div class="relative max-w-lg mx-auto divide-y-2 divide-gray-200 lg:max-w-7xl">
                    <div class="grid gap-16 lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12">

                      <div v-if="postPaginator.meta.total === 0">
                        No records found
                      </div>

                      <div v-for="post in postPaginator.data" :key="post.title">
                        <p class="text-sm text-gray-500">
                          <time :datetime="post.created_at">{{ formatDt(post.created_at) }}</time>
                        </p>
                        <RouterLink :to="{ name: 'posts.show', params: { postId: post.id } }" class="mt-2 block">
                          <p class="text-xl font-semibold text-gray-900 flex align-center">
                            {{ post.title }}
                            <span v-if="post.created_at > profile.posts_read_at" class="ml-2 inline-flex items-center px-2.5 rounded-full text-xs font-medium bg-green-100 text-green-800"> new </span>
                          </p>
                          <p class="mt-3 text-base text-gray-500">
                            {{ shorten(stripTags(post.description)) }}
                          </p>
                        </RouterLink>
                        <div class="mt-3">
                          <RouterLink :to="{ name: 'posts.show', params: { postId: post.id } }" class="text-base font-semibold text-cyan-600 hover:text-cyan-500">
                            Read full story
                          </RouterLink>
                        </div>
                      </div>
                    </div>
                  </div>

              </div>
            </div>

          </div>
        </div>

      </main>
    </VWait>

  </AuthLayout>
</template>

<script>
  import AuthLayout from '@/components/layouts/AuthLayout';
  import PageHeader from '@/components/PageHeader';
  import RadarSpinner from '@/components/RadarSpinner';
  import FormatsDt from '@/mixins/FormatsDt';
  import PostService from '@/service/PostService';
  import {mapState} from 'vuex';

  const length = 100;

  export default {
    components: {
      RadarSpinner,
      PageHeader,
      AuthLayout,
    },

    mixins: [FormatsDt],

    data() {
      return {
        postPaginator: null,
      };
    },

    computed: {
      ...mapState({
        profile: state => state.auth.profile,
      }),
    },

    async created() {
      this.$wait.start('data');

      this.postPaginator = await PostService.index();

      this.$wait.end('data');
    },

    mounted() {
      if (this.profile.unread_posts_count > 0) {
        PostService.read();
      }
    },

    methods: {
      shorten(description) {
        const text = description.trim();

        return text.length > length
          ? text.substring(0, length - 3) + "..."
          : text;
      },

      stripTags(description) {
        return description.replace(/<\/?[^>]+>/ig, " ");
      }
    },
  }
</script>
