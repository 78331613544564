<template>
  <AuthLayout>
    <VWait for="data">

      <template v-slot:waiting>
        <RadarSpinner/>
      </template>
      <main class="flex-1 relative pb-8 z-0 overflow-y-auto">

        <PageHeader>
          <template #name>News</template>
          <template #control>
            <RouterLink
              :to="{ name: 'posts.index' }"
              class="btn-default"
            >
              News
            </RouterLink>
          </template>
        </PageHeader>

        <div class="mt-8">
          <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="shadow sm:rounded-md sm:overflow-hidden">
              <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
                <div class="text-base max-w-prose mx-auto lg:max-w-lg lg:mx-auto">
                  <div class="prose prose-indigo text-gray-500">
                    <h3>{{ post.title }}</h3>
                    <p v-html="post.description"></p>
                    <p class="text-sm text-gray-500">
                      <time :datetime="post.created_at">{{ formatDt(post.created_at) }}</time>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </VWait>

  </AuthLayout>
</template>

<script>
  import AuthLayout from '@/components/layouts/AuthLayout';
  import PageHeader from '@/components/PageHeader';
  import RadarSpinner from '@/components/RadarSpinner';
  import PostService from '@/service/PostService';
  import FormatsDt from '@/mixins/FormatsDt';

  export default {
    components: {
      RadarSpinner,
      PageHeader,
      AuthLayout,
    },

    mixins: [FormatsDt],

    data() {
      return {
        post: null,
      };
    },

    async created() {
      this.$wait.start('data');

      this.post = await PostService.show(this.$route.params.postId);

      this.$wait.end('data');
    }
  }
</script>
