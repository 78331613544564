export default {
  methods: {
    genClientSeed(length = 16) {
      const availableChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

      let randomString = '';
      for (let i = 0; i < length; i++) {
        randomString += availableChars[Math.floor(Math.random() * availableChars.length)];
      }

      return randomString;
    },

    getClientNumberSeed(length = 4) {
      const availableChars = '123456789'

      let randomString = '';
      for (let i = 0; i < length; i++) {
        randomString += availableChars[Math.floor(Math.random() * availableChars.length)];
      }

      return randomString;
    }
  }
}
