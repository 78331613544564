<template>
  <Notifications position="top right" aria-live="assertive" class="flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start" width="370px">
    <template #body="props" class="">
      <transition enter-active-class="transform ease-out duration-300 transition" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <div class="hidden sm:block max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <ExclamationCircleIcon v-if="props.item.type === 'error'" class="h-6 w-6 text-red-400" aria-hidden="true" />
                <CheckCircleIcon v-else class="h-6 w-6 text-green-600" aria-hidden="true" />
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900">
                  {{ props.item.title }}
                </p>
                <p class="mt-1 text-sm text-gray-500 truncate">
                  {{ props.item.text }}
                </p>
              </div>
              <div class="ml-4 flex-shrink-0 flex">
                <button @click="props.close" class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500">
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </template>
  </Notifications>
</template>

<script>
  import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/vue/24/outline'
  import { XMarkIcon } from '@heroicons/vue/20/solid'
  import { ref } from '@vue/reactivity';

  export default {
    components: {
      CheckCircleIcon,
      ExclamationCircleIcon,
      XMarkIcon,
    },

    props: {
      item: Object,
    },
  }
</script>

<style scoped>

</style>
