import Post from '@/resources/Post';
import axios from 'axios';

const PostService = {
  index() {
    return Post.get();
  },

  show(id) {
    return Post.find(id);
  },

  read() {
    return axios.patch('/posts/read');
  }
}

export default PostService;
