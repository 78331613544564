<template>
  <!-- Page header -->
  <div class="bg-white shadow">
    <div class="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
      <div class="py-6 flex items-center justify-between lg:border-t lg:border-gray-200">
        <div class="inline-flex min-w-0">
          <!-- Profile -->
          <div class="flex items-center">
            <div>
              <div class="flex items-center">
                <h1 class="sm:ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                  <slot name="name"></slot>
                </h1>
              </div>
              <dl class="flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                <dd v-if="profile?.email_verified_at" class="mt-3 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize">
                  <CheckCircleIcon class="flex-shrink-0 mr-1.5 h-5 w-5 text-green-600" aria-hidden="true" />
                  Verified
                </dd>
                <RouterLink :to="{ name: 'email.verify' }" v-else class="mt-3 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize hover:underline">
                  <ExclamationCircleIcon class="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400" aria-hidden="true" />
                  Not verified
                </RouterLink>
              </dl>
            </div>
          </div>
        </div>
        <div class="inline-flex space-x-3 md:mt-0 md:ml-4">
          <slot name="control"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    CheckCircleIcon,
    ExclamationCircleIcon,
  } from '@heroicons/vue/20/solid'
  import { mapState } from 'vuex';

  export default {
    components: {
      CheckCircleIcon,
      ExclamationCircleIcon,
    },

    computed: {
      ...mapState({
        profile: state => state.auth.profile,
      })
    },
  }
</script>

<style scoped>

</style>
