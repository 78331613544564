import AuthService from '@/service/AuthService';
import {useStore} from 'vuex';
import AuthApi from '@/api/AuthApi';
import PersistentConfig from '@/resources/PersistentConfig';
import Wallet from '@/resources/Wallet';

export default async function auth({ to, next, store }) {
  if (AuthService.isLoggedIn()) {
    const [profileResponse, wallet, persistentConfig] = await Promise.all([
      AuthApi.profile(),
      Wallet.get(),
      PersistentConfig.all(),
    ]);

    await store.dispatch('auth/setProfile', profileResponse.data);
    await store.commit('wallet/setCurrencies', wallet);
    await store.dispatch('persistentConfig/store', persistentConfig);

    next();
  } else {
    next({
      name: 'login',
      query: { redirect: to.fullPath },
    });
  }
}
